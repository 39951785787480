<template>
    <Head>
        <title>Blog santé Allô Doto - Votre blog Santé en Afrique</title>
        <meta
            head-key="description"
            name="description"
            content="Allô Doto; savoir, c'est pouvoir; des informations utiles pour votre santé en Afrique: Votre santé simplement"
        />
    </Head>
    <div class="" id="app">
        <Header></Header>

        <section
            ref="content"
            class="mx-auto px-4 lg:px-20 bg-white sticky top-0 z-50"
            id="content"
        >
            <div class="flex items-center pt-3">
                <div
                    class="text-xl font-semibold text-gray-700 sticky-logo hidden"
                >
                    <a
                        class="text-2xl font-medium text-sky-500 transition-colors flex items-center duration-300 transform dark:text-sky-400 hover:text-sky-400 dark:hover:text-sky-300"
                        href="/"
                    >
                        <img
                            src="/images/allo_logo.svg"
                            alt=""
                            srcset=""
                            class="h-6"
                        />
                    </a>
                </div>
                <div class="flex justify-center w-full">
                    <div
                        class="lg:w-2/5 md:w-3/5 sm:w-full rounded-lg bg-slate-100 flex py-2 px-2 justify-between"
                    >
                        <input
                            placeholder="Rechercher un article"
                            v-model="search"
                            class="bg-slate-100 w-full mr-2"
                        />
                        <button
                            class="bg-slate-800 h-10 text-center rounded-md text-slate-800 px-4 bg-opacity-25"
                            @click.prevent="makeSearch"
                        >
                            Rechercher
                        </button>
                    </div>
                </div>
                <div class="sticky-user hidden" v-if="isLoading"></div>
                <div
                    v-else
                    x-cloak
                    :class="[
                        isOpen
                            ? 'translate-x-0 opacity-100 '
                            : 'opacity-0 -translate-x-full',
                    ]"
                    class="inset-x-0 z-20 absolute w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white dark:bg-gray-800 lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:bg-transparent lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center"
                >
                    <div
                        class="flex flex-col -mx-6 lg:flex-row lg:items-center sticky-user hidden"
                    >
                        <a
                            v-if="!user"
                            href="/login"
                            class="text-primary border border-primary outline-none dark:text-primary hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-md px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                        >
                            Connexion</a
                        >
                        <button
                            v-else-if="user"
                            type="button"
                            class="flex mr-3 text-sm bg-white rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                            id="user-menu-button"
                            aria-expanded="false"
                            data-dropdown-toggle="user-dropdown"
                            data-dropdown-placement="bottom"
                            @click="toggle"
                        >
                            <span class="sr-only">Open user menu</span>

                            <Avatar
                                v-if="user?.avatar"
                                :image="user?.avatar"
                                class="bg-white border-2 shadow-none border-2 border-primary3"
                                shape="circle"
                            />

                            <Avatar
                                v-else
                                class="bg-white border-2 border-white shadow-none border-2 border-primary3"
                                shape="circle"
                                :label="user?.first_name?.charAt(0)"
                            />
                        </button>
                        <!-- Dropdown menu -->
                        <div
                            class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                            id="user-dropdown"
                        >
                            <Menu
                                ref="menu"
                                id="overlay_menu"
                                :model="items"
                                :popup="true"
                            >
                                <template #start>
                                    <Button
                                        class="w-full shadow-none rounded-none p-link flex items-center p-2 pl-3 text-color hover:surface-200 border-b border-slate-300"
                                    >
                                        <div
                                            class="flex flex-col justify-center"
                                        >
                                            <span
                                                class="font-medium font-ibm"
                                                >{{ user?.full_name }}</span
                                            >
                                            <span
                                                class="text-xs font-qs text-left"
                                                >{{ user?.username }}</span
                                            >
                                        </div>
                                    </Button>
                                </template>
                                <template #item="{ item, label, props }">
                                    <a
                                        class="flex"
                                        v-bind="props.action"
                                        :href="item.url"
                                    >
                                        <span v-bind="props.icon" />
                                        <span v-bind="props.label">{{
                                            label
                                        }}</span>
                                        <Badge
                                            v-if="item.badge"
                                            class="ml-auto"
                                            :value="item.badge"
                                        />
                                    </a>
                                </template>
                                <template #end>
                                    <button
                                        class="w-full p-link flex items-center p-2 pl-4 text-color rounded-none hover:surface-200 border-t border-slate-300"
                                        @click="logout"
                                    >
                                        <i class="la la-sign-out text-danger" />
                                        <span class="ml-2 text-danger"
                                            >Déconnexion</span
                                        >
                                    </button>
                                </template>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col items-start justify-center">
                <div class="flex flex-wrap mt-5 w-full justify-center">
                    <span
                        v-for="(cat, index) in categories"
                        :key="index"
                        :class="`py-2 px-4 ${
                            isSelectedCategory(cat)
                                ? 'bg-primary text-white'
                                : 'bg-slate-100 text-slate-400'
                        } rounded-xl  cursor-pointer mr-3 mb-3 transition transition-all`"
                        @click="handleNewCategorySelect(cat)"
                    >
                        {{ cat.name }}
                    </span>
                </div>
            </div>
        </section>

        <section class="mx-auto lg:px-20 px-4 py-8">
            <div class="flex items-center justify-between hidden">
                <h2 class="text-gray-800 font-bold text-3xl"></h2>

                <button
                    class="flex items-center px-4 py-2.5 font-medium tracking-wide text-slate-400 capitalize transition-colors duration-300 transform border border-slate-400 rounded-lg hover:bg-sky-50 focus:outline-none focus:ring focus:ring-sky-300 focus:ring-opacity-80"
                >
                    <span class="mx-1">Tout voir</span>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 mx-1 h-6"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                    </svg>
                </button>
            </div>

            <div class="my-5" v-if="!isLoadingPosts && posts.length == 0">
                <p class="text-lg text-center text-slate-500">Aucun article</p>
            </div>

            <div
                class="grid grid-cols-3 gap-8 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 mt-12 xl:grid-cols-3"
            >
                <div
                    v-for="(post, index) in posts"
                    :key="index"
                    class="bg-primary2 bg-opacity-8 rounded-lg overflow-hidden transition transition-all"
                >
                    <img
                        class="object-cover object-center w-full h-24 lg:h-40"
                        :src="post.thumbnail_url"
                        alt=""
                    />

                    <div class="mt-5 px-3">
                        <div class="flex justify-between items-center">
                            <span
                                class="py-2 px-3 bg-primary2 bg-opacity-20 text-sm rounded-md text-primary2"
                            >
                                {{ post.category.name }}
                            </span>

                            <span class="text-slate-500 font-medium text-sm">{{
                                moment(post.published_at).format("LL")
                            }}</span>
                        </div>

                        <h3 class="my-3 text-slate-400 text-sm">
                            Par {{ post.signature }}
                        </h3>

                        <h1
                            class="text-lg font-semibold text-gray-800 dark:text-white truncate"
                        >
                            {{ post.title }}
                        </h1>

                        <p class="mt-2 text-slate-500 dark:text-gray-400">
                            {{ post?.excerpt ?? "-" }}
                        </p>

                        <div class="flex items-center justify-end my-4">
                            <a
                                :href="`/en-bonne-sante/${post.slug}`"
                                class="inline-block text-primary2 no-underline hover:text-sky-400"
                                >Lire <i class="la la-angle-double-right"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center my-5">
                <InfiniteLoading @infinite="load">
                    <template #complete>
                        <span></span>
                    </template>
                </InfiniteLoading>
            </div>
        </section>

        <section class="container mx-auto px-4 py-10 lg:py-16 hidden">
            <div class="flex items-center justify-between bg-white z-50 my-2">
                <h2 class="text-gray-800 font-bold text-3xl">Most Popular</h2>

                <button
                    class="flex items-center px-4 py-2.5 font-medium tracking-wide text-slate-400 capitalize transition-colors duration-300 transform border border-slate-400 rounded-lg hover:bg-sky-50 focus:outline-none focus:ring focus:ring-sky-300 focus:ring-opacity-80"
                >
                    <span class="mx-1">Exploer All</span>

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 mx-1 h-6"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        />
                    </svg>
                </button>
            </div>

            <div
                class="grid grid-cols-1 gap-8 md:grid-cols-2 mt-12 xl:grid-cols-3"
            >
                <div>
                    <img
                        class="object-cover object-center w-full h-64 rounded-lg lg:h-80"
                        src="https://images.unsplash.com/photo-1624996379697-f01d168b1a52?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                        alt=""
                    />

                    <div class="mt-8">
                        <span class="text-sky-500 uppercase tracking-wider"
                            >Article</span
                        >

                        <h1
                            class="mt-4 text-xl font-semibold text-gray-800 dark:text-white truncate"
                        >
                            What do you want to know about UI
                        </h1>

                        <p class="mt-2 text-gray-500 dark:text-gray-400">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Nam est asperiores vel, ab animi recusandae
                            nulla veritatis id tempore sapiente
                        </p>

                        <div class="flex items-center justify-between mt-4">
                            <div>
                                <a
                                    href="#"
                                    class="text-lg font-medium text-gray-700 dark:text-gray-300 hover:underline hover:text-gray-500"
                                >
                                    John snow
                                </a>

                                <p
                                    class="text-sm text-gray-500 dark:text-gray-400"
                                >
                                    February 1, 2022
                                </p>
                            </div>

                            <a
                                href="#"
                                class="inline-block text-sky-500 underline hover:text-sky-400"
                                >Read more</a
                            >
                        </div>
                    </div>
                </div>

                <div>
                    <img
                        class="object-cover object-center w-full h-64 rounded-lg lg:h-80"
                        src="https://images.unsplash.com/photo-1590283603385-17ffb3a7f29f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                        alt=""
                    />

                    <div class="mt-8">
                        <span class="text-sky-500 uppercase tracking-wider"
                            >Article</span
                        >

                        <h1
                            class="mt-4 text-xl font-semibold text-gray-800 dark:text-white truncate"
                        >
                            All the features you want to know
                        </h1>

                        <p class="mt-2 text-gray-500 dark:text-gray-400">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Nam est asperiores vel, ab animi recusandae
                            nulla veritatis id tempore sapiente
                        </p>

                        <div class="flex items-center justify-between mt-4">
                            <div>
                                <a
                                    href="#"
                                    class="text-lg font-medium text-gray-700 dark:text-gray-300 hover:underline hover:text-gray-500"
                                >
                                    Arthur Melo
                                </a>

                                <p
                                    class="text-sm text-gray-500 dark:text-gray-400"
                                >
                                    February 6, 2022
                                </p>
                            </div>

                            <a
                                href="#"
                                class="inline-block text-sky-500 underline hover:text-sky-400"
                                >Read more</a
                            >
                        </div>
                    </div>
                </div>

                <div>
                    <img
                        class="object-cover object-center w-full h-64 rounded-lg lg:h-80"
                        src="https://images.unsplash.com/photo-1597534458220-9fb4969f2df5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80"
                        alt=""
                    />

                    <div class="mt-8">
                        <span class="text-sky-500 uppercase tracking-wider"
                            >Article</span
                        >

                        <h1
                            class="mt-4 text-xl font-semibold text-gray-800 dark:text-white truncate"
                        >
                            Which services you get from Meraki UI
                        </h1>

                        <p class="mt-2 text-gray-500 dark:text-gray-400">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Nam est asperiores vel, ab animi recusandae
                            nulla veritatis id tempore sapiente
                        </p>

                        <div class="flex items-center justify-between mt-4">
                            <div>
                                <a
                                    href="#"
                                    class="text-lg font-medium text-gray-700 dark:text-gray-300 hover:underline hover:text-gray-500"
                                >
                                    Tom Hank
                                </a>

                                <p
                                    class="text-sm text-gray-500 dark:text-gray-400"
                                >
                                    February 19, 2022
                                </p>
                            </div>

                            <a
                                href="#"
                                class="inline-block text-sky-500 underline hover:text-sky-400"
                                >Read more</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer></Footer>
    </div>
</template>
<script setup>
// import Footer from "./Footer.vue";
import Footer from "@/components/footer/index.vue";

import Header from "./Header.vue";
import Badge from "primevue/badge";
import { onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import { useRoute, useRouter } from "vue-router";
const props = defineProps({ categories: Array });
const isOpen = ref(false);

const search = ref("");
const content = ref();

const isLoadingPosts = ref(false);

const store = useStore();

const route = useRoute();

const router = useRouter();

const selectedCategories = ref([]);

const page = ref(1);

const posts = ref([]);

const oldState = ref();

const handleNewCategorySelect = (newCat) => {
    if (selectedCategories.value.map((x) => x.id).includes(newCat.id)) {
        selectedCategories.value = selectedCategories.value.filter(
            (x) => x.id !== newCat.id
        );
    } else selectedCategories.value.push(newCat);

    router.push({
        path: route.path,
        query: { categories: selectedCategories.value.map((x) => x.slug) },
    });
    page.value = 1;
    posts.value = [];
    load(oldState.value);
};

const makeSearch = () => {
    page.value = 1;
    posts.value = [];
    load(oldState.value);
};

const load = async ($state) => {
    try {
        oldState.value = $state;

        const filter = {
            categories: selectedCategories.value.map((x) => x.id),
            page: page.value,
        };

        if (search) {
            filter.search = search.value;
        }

        store.dispatch("blog/postsSearch", {
            filter,
            onSuccess: ({ data, current_page, last_page }) => {
                posts.value.push(...data);

                if (data.length < 10) $state.complete();
                else {
                    $state.loaded();
                }

                page.value += 1;
                // isLoadingPosts.value = false;
            },
            onError: () => {
                isLoadingPosts.value = false;
                $state.error();
            },
        });
    } catch (error) {
        $state.error();
    }
};

const isSelectedCategory = (category) => {
    return selectedCategories.value.map((x) => x.id).includes(category.id);
};

onBeforeMount(() => {
    if (typeof window != "undefined") {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const c = urlParams.getAll("categories");

        c.forEach((e) => {
            const v = props.categories.find((x) => x.slug == e);

            if (v) selectedCategories.value.push(v);
        });
    }
});

onMounted(() => {
    const el = document.querySelector("#content");

    window.addEventListener("scroll", () => {
        const stickyTop = parseInt(window.getComputedStyle(el).top);
        const currentTop = el.getBoundingClientRect().top;
        el.classList.toggle("is-sticky", currentTop === stickyTop);
    });
});
</script>
<style lang="scss">
.is-sticky {
    .sticky-logo {
        display: block !important;
    }
    .sticky-user {
        display: block !important;
    }
}
</style>
