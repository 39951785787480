<template>
    <div class="w-full flex flex-col justify-center items-center">
        <div class="w-11/12 flex justify-center">
            <div
                v-if="appointment"
                class="md:w-3/6 w-1/2 sm:w-full flex flex-col bg-white rounded-lg mt-14 mb-4 shadow-lg"
            >
                <div
                    class="h-10 flex bg-slate-600 items-center p-3 text-sm text-white"
                >
                    <span class="capitalize">
                        <i class="la la-calendar mr-1"></i
                        >{{
                            moment(appointment.start_date).format("dddd LL")
                        }}</span
                    >
                    <span>
                        <i class="la la-clock ml-2 mr-1"></i
                        >{{
                            moment(appointment.start_date).format("HH:mm")
                        }}</span
                    >
                </div>
                <div class="bg-success bg-opacity-25 text-white">
                    <p class="font-bold text-success py-2 px-2 text-sm">
                        <i
                            class="la la-check-circle text-success font-bold"
                        ></i>
                        Rendez-vous confirmé
                    </p>
                    <p class="text-success ml-5 pb-3 text-sm">
                        Vous avez recu un mail ou SMS de notification
                    </p>
                </div>

                <div class="bg-white p-3">
                    <strong class="font-semibold" v-if="appointment.pratician"
                        >Dr. {{ appointment.pratician?.full_name }}</strong
                    >
                    <p
                        v-if="appointment.medical_center"
                        class="text-slate-400 text-sm py-1"
                    >
                        {{ appointment.medical_center?.name }}
                    </p>
                    <p class="text-slate-500">
                        {{ appointment.specialty?.name }}
                    </p>
                </div>

                <div class="border-t-2 border-b-2 border-[#F8F9FC] p-3">
                    <p>{{ appointment?.patient?.full_name }}</p>
                    <p
                        v-if="appointment.patient"
                        class="text-slate-400 text-xs"
                    >
                        {{ appointment.patient?.username }}
                    </p>
                </div>

                <div class="p-3 flex items-center">
                    <add-to-calendar-button
                        :name="`[Allô Doto] Rendez-vous médical avec Dr. ${
                            appointment.pratician?.full_name
                        } ${
                            appointment.medical_center?.name
                                ? ` - ${appointment.medical_center?.name}`
                                : ''
                        }`"
                        :startDate="
                            moment(appointment.start_date).format('yyyy-MM-DD')
                        "
                        :startTime="
                            moment(appointment.start_date).format('HH:mm')
                        "
                        :endTime="moment(appointment.end_date).format('HH:mm')"
                        :timeZone="timeZone"
                        language="fr"
                        organizer="Allô Doto|sos@allodoto.com"
                        :location="appointment.address"
                        options="'Apple','Google','Outlook.com','Yahoo'"
                        label="Ajouter à mon agenda"
                        size="5"
                        lightMode="bodyScheme"
                    ></add-to-calendar-button>

                    <button
                        class="flex ml-2 bg-white px-2 py-3 h-10 items-center text-sm cursor-pointer rounded hover:bg-slate-400 hover:text-white"
                        @click="navigate"
                    >
                        <i class="la la-directions mr-3 text-xl"></i>
                        Itinéraire
                    </button>
                </div>
            </div>
        </div>
        <div class="flex justify-center items-center">
            <a href="/account/appointments" class="text-sm font-qs"
                >Aller sur mon espace personnel</a
            >
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import "add-to-calendar-button";
export default defineComponent({
    props: {
        data: { type: Object, required: true },
    },
    setup(props) {
        const timeZone = computed(
            () => Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        const appointment = computed(() => props.data);

        const getPosition = () => {
            fetch("https://api.ipregistry.co/?key=c33s648p0gjrije7")
                .catch(function (err) {
                    console.log("Error", err);
                })
                .then(function (response) {
                    return response.json();
                })
                .then(function (payload) {
                    timeZone.value = payload.time_zone?.id;
                });
        };

        const navigate = () => {
            const urlSuffix = `${props.data.address_latitude},${props.data.address_longitude}`;
            if (typeof window != "undefined")
                window.open(
                    "https://www.google.com/maps/search/?api=1&query=" +
                        urlSuffix,
                    "_blank"
                );
        };
        onMounted(() => {
            getPosition();
        });

        return { appointment, timeZone, moment, navigate };
    },
});
</script>
