<template>
    <footer class="flex flex-col items-center font-ibm bg-black">
        <div class="py-20 w-10/12 flex xs:flex-col bg-slate-">
            <div
                class="logo-box lg:w-1/4 xl:w-1/4 md:w-1/4 xs:w-full sm:w-full font-ibm"
            >
                <div class="flex mb-10">
                    <a href="/">
                        <img
                            src="/images/small_dark.png"
                            class="mr-3 w-20"
                            alt="Allô Doto Logo"
                    /></a>
                </div>

                <p class="text-sm text-gray-400 py-3 font-ibm">
                    &copy; Copyright
                    <strong>
                        <a target="_blank" href="https://habilis.bj"
                            >Habilis</a
                        ></strong
                    >
                    2024
                </p>
            </div>
            <div class="w-3/4 footer-items flex font-ibm justify-between">
                <div class="flex flex-col w-1/3">
                    <h3
                        class="font-ibm text-md text-gray-500 font-bold uppercase"
                    >
                        Entreprise
                    </h3>
                    <a href="/legal-notice" class="py-2 text-gray-400"
                        >À Propos</a
                    >
                    <a href="/our-mission" class="py-2 text-gray-400"
                        >Notre mission</a
                    >
                    <a href="" class="py-2 text-gray-400">Partners</a>
                </div>

                <div class="flex flex-col w-1/3">
                    <h3
                        class="font-ibm text-md text-gray-500 uppercase font-bold"
                    >
                        Légal
                    </h3>
                    <a href="/legal-notice" class="py-2 text-gray-400"
                        >Mentions légales</a
                    >
                    <a href="/contact" class="py-2 text-gray-400"
                        >Réclamations</a
                    >
                    <a href="/security-policy" class="py-2 text-gray-400"
                        >Politique de confidentialité</a
                    >
                    <a href="/terms" class="py-2 text-gray-400"
                        >Conditions d'utilisation</a
                    >
                </div>

                <div class="w-1/3 alert xs:hidden">
                    <div class="rounded-md p-4 bg-primary bg-opacity-25">
                        <p
                            class="text-primary flex items-center font-bold font-ibm"
                        >
                            <span class="text-primary mr-2">
                                <svg
                                    viewBox="0 0 18 18"
                                    width="18"
                                    height="18"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M11.2 1.4l6.5 12.9c.9 1.7-.4 3.7-2.3 3.7H2.6C.7 18-.6 16 .3 14.3L6.8 1.4A2.6 2.6 0 019 0a2.6 2.6 0 012.2 1.4zM10 8a1 1 0 00-2 0v3a1 1 0 102 0V8zm0 6a1 1 0 11-2 0 1 1 0 012 0z"
                                    ></path>
                                </svg>
                            </span>

                            Avertissement
                        </p>

                        <p class="mt-5 text-primary font-ibm">
                            <strong>Allô Doto </strong> n’est pas un service
                            d’urgence (sauf Exception
                            <a class="text-secondary" href="/terms#emergency"
                                >"Demande d'intervention ou alerte"</a
                            >) ou un cabinet médical, pour toute urgence,
                            appelez le 18 (numéro d’urgence de votre pays de
                            résidence)
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="bg-white sm:px-0 py-5 md:px-20 xl:px-20 lg:px-20 flex social justify-between mb-0"
        >
            <div class="flex justify-start items-center">
                <div class="mr-2">
                    <a
                        href="https://www.facebook.com/allodoto"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><i class="lab la-facebook text-3xl text-[#4267B2]"></i
                    ></a>
                </div>
                <div class="mr-2">
                    <a
                        href="https://www.instagram.com/allodoto/"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><i class="lab la-instagram text-3xl text-[#E1306C]"></i
                    ></a>
                </div>
                <div class="mr-2">
                    <a
                        href="https://twitter.com/allo_doto"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="text-blackp"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-twitter-x"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z"
                            />
                        </svg>
                    </a>
                </div>
            </div>
            <div class="flex">
                <country-flag
                    v-tooltip.top="'Nos services sont disponibles au Bénin'"
                    country="bj"
                    rounded
                    shadow
                    class="mr-1"
                ></country-flag>
                <country-flag
                    v-tooltip.top="
                        'Nos services sont disponibles en Côte d\'Ivoire'
                    "
                    country="ci"
                    rounded
                    shadow
                    class=""
                ></country-flag>
            </div>
        </div>
    </footer>
</template>
