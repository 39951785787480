
/* @vite-ignore */
import { createI18n } from 'vue-i18n'

async function loadLocaleMessages(lang) {
    // const locales = import.meta.glob(
    //     './locales',
    //     true,
    //     /[A-Za-z0-9-_,\s]+\.json$/i
    // )


    let messages = {}
    const key = `@/lang/locales/${lang.toLowerCase()}.json`
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
        const locale = matched[1]
        const { default: data } = async () => await import(key, { assert: { type: "json" } })
        // const { default: data } = await import(`${key}`, { with: { type: "json" } })

        messages = { ...messages, [locale]: data }
    }

    return messages
}


const locale = typeof window !== 'undefined' ? navigator.language.split('-') : ['fr']

console.log('locale', locale);
const lang = locale[0] ?? 'fr'
const i18n = createI18n({
    warnHtmlInMessage: 'off',
    locale: lang,
    fallbackLocale: lang,
    enableLegacy: true,
    messages: loadLocaleMessages(lang)
})

export default i18n


