<template>
    <div
        :class="`flex border-r border-t border-l ${
            isLast ? 'border-b' : ''
        } border-slate-300 py-3 px-2 rounded-[2px]`"
    >
        <div class="w-1/2 flex flex-col items-start">
            <h3 v-if="appointment?.pratician">
                Dr. {{ appointment?.pratician?.full_name }}
            </h3>
            <p class="pt-1 text-slate-500 text-sm">
                {{ appointment?.medical_center?.name }}
            </p>

            <div class="flex justify-start items-center">
                <p class="text-sm pr-1 text-slate-600">Status:</p>
                <h3 :class="`font-medium text-${colors[appointment.status]}`">
                    {{ statuses[appointment.status] }}
                </h3>
            </div>
        </div>
        <div class="w-1/2 flex flex-col justify-end">
            <div class="flex justify-end">
                <Button
                    v-if="canDisplayEvalBtn"
                    class="shadow-none bg-warning h-10 px-3 py-2 mr-2 text-white font-normal"
                    label=""
                    icon="la text-white"
                    @click="displayEvaluation"
                >
                    <i class="la la-meh-blank text-xl"></i>
                </Button>
                <Button
                    class="shadow-none bg-slate-400 h-10 px-3 py-2 text-white font-normal"
                    label=""
                    icon="la  text-white"
                    @click="gotTo"
                >
                    <i class="la la-arrow-right"></i>
                </Button>

                <Menu
                    ref="menu"
                    id="overlay_menu"
                    :model="items"
                    :popup="true"
                />
            </div>
            <div class="mt-3">
                <p class="text-end text-sm font-ibm font-medium capitalize">
                    {{ moment(appointment?.start_date).format("LLLL") }}
                </p>
            </div>
        </div>
    </div>
    <Dialog
        v-model:visible="showEvaluation"
        :draggable="false"
        modal
        header="Evaluez votre rendez-vous"
        :style="{ width: isSmall || isMedium ? '80vw' : '50vw' }"
    >
        <div class="">
            <div
                class="flex items-center my-3"
                v-for="(motive, index) in motives"
                :key="index"
            >
                <div class="w-3/5">
                    <h3 class="text-sm font-ibm">{{ motivesNames[motive] }}</h3>
                </div>
                <div class="flex w-2/5 justify-between">
                    <div
                        class="mr-2 cursor-pointer"
                        v-for="(elm, i) in values"
                        :key="i"
                        @click="handleUserEvaluation(motive, elm.value)"
                    >
                        <el-tooltip
                            :content="faceTexts[motive][elm.value]"
                            placement="top"
                            effect="customized"
                        >
                            <i
                                :class="`la la-${elm.icon} text-3xl ${
                                    evalForm[motive] == elm.value
                                        ? `text-${faceColors[elm.value]}`
                                        : ''
                                }`"
                            ></i>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-4 w-full">
            <Textarea
                v-model="evalForm.comment"
                rows="5"
                :disabled="!!(appointment?.evaluations ?? []).length"
                class="shadow-none resize-none rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
            />
        </div>

        <template #footer>
            <Button
                label="Fermer"
                icon="la la-times"
                class="px-3 py-2 text-sm mr-2 shadow-none"
                @click="showEvaluation = false"
                text
            />
            <Button
                v-if="!(appointment?.evaluations ?? []).length"
                label="Valider"
                class="px-3 py-2 text-sm bg-primary2 text-white font-mediun shadow-none"
                icon="la la-check"
                :disabled="disabledEvaluation"
                @click="validateEvaluation"
                autofocus
            />
        </template>
    </Dialog>
    <Toast />
</template>
<script>
import Textarea from "primevue/textarea";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Menu from "primevue/menu";
import { useWindowSize } from "@vueuse/core";
import { ElTooltip } from "element-plus";
import { computed, onMounted, ref } from "vue";
import moment from "moment";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
export default {
    components: { Button, Menu, Toast, Dialog, Textarea, ElTooltip },
    props: {
        isLast: { tye: Boolean, default: false },
        appointment: { tye: Object, required: true },
    },
    setup(props) {
        const { width, height } = useWindowSize();
        const store = useStore();
        const toast = useToast();
        const isSmall = computed(() => width.value <= 540);
        const isMedium = computed(
            () => width.value <= 1000 && width.value > 540
        );
        const isLarge = computed(() => width.value > 1000);
        const showEvaluation = ref(false);

        const router = useRouter();
        const diffInDays = computed(() =>
            moment().diff(moment(props.appointment.start_date), "days")
        );

        const canDisplayEvalBtn = computed(
            () =>
                (diffInDays.value <= 1 && props.appointment.status == "done") ||
                props.appointment?.evaluations?.length > 0
        );
        const motivesNames = ref({
            setting_and_cleanliless: "Confort, propreté, localisation",
            practitioner_professionalism: "Professionnalisme du praticien",
            quality_of_contact: "Écoute, attention, accueil",
            clarity_of_explanations: "Compréhension des soins",
            average_waiting_time:
                "Entre l'horaire du rendez-vous et celui de la consultation",
            general_recommendation:
                "Recommanderiez-vous ce praticien à un(e) ami(e) ?",
        });

        const disabledEvaluation = computed(
            () =>
                !evalForm.value.average_waiting_time ||
                !evalForm.value.setting_and_cleanliless ||
                !evalForm.value.clarity_of_explanations ||
                !evalForm.value.general_recommendation ||
                !evalForm.value.quality_of_contact ||
                !evalForm.value.practitioner_professionalism
        );

        const faceTexts = ref({
            setting_and_cleanliless: {
                1: "Faché",
                2: "Pas mal",
                3: "Bien",
                4: "Très bien",
                5: "Excéllent",
            },
            practitioner_professionalism: {
                1: "Non, ça ne va pas",
                2: "J'ai des reproches",
                3: "Acceptable",
                4: "Aucun problème",
                5: "Très professionel(le)",
            },
            quality_of_contact: {
                1: "Faché",
                2: "Pas mal",
                3: "Bien",
                4: "Très bien",
                5: "Excéllent",
            },
            clarity_of_explanations: {
                1: "Difficultés de compréhension",
                2: "Pas mal",
                3: "Ouiiiiii, mais..",
                4: "Claires",
                5: "Très claires",
            },
            average_waiting_time: {
                1: "Plus d'une heure",
                2: "Longue attente",
                3: "Supportable",
                4: "Un peu",
                5: "Pas d'attente",
            },
            general_recommendation: {
                1: "Jamais",
                2: "Non",
                3: "Oui, pourquoi pas",
                4: "Oui",
                5: "Sans le moindre doute",
            },
        });

        const faceColors = ref({
            1: "danger",
            2: "info",
            3: "warning",
            4: "primary3",
            5: "success",
        });

        const values = computed(() => [
            {
                value: 1,
                name: "Faché",
                icon: "dizzy",
            },
            {
                value: 2,
                name: "Pas mal",
                icon: "flushed",
            },
            {
                value: 3,
                name: "Bien ",
                icon: "grin",
            },
            {
                value: 4,
                name: "Très bien",
                icon: "smile-beam",
            },
            {
                value: 5,
                name: "Excéllent",
                icon: "grin-hearts",
            },
        ]);

        const motives = computed(() => [
            "average_waiting_time",
            "practitioner_professionalism",
            "setting_and_cleanliless",
            "quality_of_contact",
            "clarity_of_explanations",
            "general_recommendation",
        ]);

        const evalForm = ref({
            setting_and_cleanliless:
                (props.appointment?.evaluations ?? [])?.find(
                    (x) => x.motive == "setting_and_cleanliless"
                )?.motive_value ?? null,
            practitioner_professionalism:
                (props.appointment?.evaluations ?? [])?.find(
                    (x) => x.motive == "practitioner_professionalism"
                )?.motive_value ?? null,
            quality_of_contact:
                (props.appointment?.evaluations ?? [])?.find(
                    (x) => x.motive == "quality_of_contact"
                )?.motive_value ?? null,
            clarity_of_explanations:
                (props.appointment?.evaluations ?? [])?.find(
                    (x) => x.motive == "clarity_of_explanations"
                )?.motive_value ?? null,
            average_waiting_time:
                (props.appointment?.evaluations ?? [])?.find(
                    (x) => x.motive == "average_waiting_time"
                )?.motive_value ?? null,
            general_recommendation:
                (props.appointment?.evaluations ?? [])?.find(
                    (x) => x.motive == "general_recommendation"
                )?.motive_value ?? null,
            comment:
                (props.appointment?.evaluations ?? [])?.find(
                    (x) => x.motive == "comment"
                )?.comment ?? null,
        });
        const colors = computed(() => ({
            cancelled: "danger",
            confirmed: "success",
            done: "success",
            pending: "slate-500",
            patient_absent: "warning",
            pratician_absent: "warning",
        }));

        const statuses = computed(() => ({
            cancelled: "Annulé",
            done: "Présent",
            confirmed: "Confirmé",
            pending: "En attente",
            patient_absent: "Patient absent",
            pratician_absent: "Praticien absent",
        }));

        const menu = ref();
        const items = ref([
            {
                label: "Éditer",
                icon: "la la-pen",
                command: () => {},
            },
            {
                label: "Annuler",
                icon: "la la-ban text-danger",
                command: () => {},
            },
        ]);

        const validateEvaluation = () => {
            store.dispatch("medical/rateAppointment", {
                data: evalForm.value,
                id: props.appointment.id,
                onSuccess: () => {
                    showEvaluation.value = false;
                    toast.add({
                        severity: "success",
                        summary: "Évaluation",
                        detail: "Merci de votre évaluation. Il sera bien pris en compte",
                        life: 3000,
                    });
                },
                onError: () => {
                    toast.add({
                        severity: "error",
                        summary: "Évaluation",
                        detail: "Ooops, une erreur est survenue, veuillez rééssayer plus tard",
                        life: 3000,
                    });
                },
            });
        };
        const handleUserEvaluation = (field, value) => {
            if ((props.appointment?.evaluations ?? []).length > 0) return;
            evalForm.value[field] = value;
        };
        const displayEvaluation = () => {
            showEvaluation.value = true;
        };
        const toggle = (event) => {
            menu.value.toggle(event);
        };
        const gotTo = () => {
            router.push({
                name: "patient.appointment",
                params: { appointmentId: props.appointment.id },
            });
        };
        onMounted(() => {
            console.log(props.isLast);
        });

        return {
            items,
            toggle,
            validateEvaluation,
            gotTo,
            handleUserEvaluation,
            displayEvaluation,
            diffInDays,
            canDisplayEvalBtn,
            disabledEvaluation,
            faceColors,
            values,
            evalForm,
            faceTexts,
            motivesNames,
            colors,
            statuses,
            moment,
            menu,
            isSmall,
            isMedium,
            motives,
            showEvaluation,
        };
    },
};
</script>
<style lang="scss">
.el-popper.is-customized {
    @apply bg-slate-500 text-white;
}
.el-popper.is-customized .el-popper__arrow::before {
    @apply bg-slate-500;
    right: 0;
}
</style>
