import axios from "./axios";
import { auth, portfolio, transactions } from "./endpoint";

export default {
    async getPortfolios() {
        const response = await axios.get(portfolio.get());
        return response.data;
    },
    async authenticate(credentials) {
        const response = await axios.post(auth.login(), credentials);
        return response.data;
    },
    async register(data) {
        const response = await axios.post(auth.register(), data);
        return response.data;
    },
    async resetAccount(credentials) {
        const response = await axios.post(auth.reset(), credentials);
        return response.data;
    },
    async generateAgoraToken(channelName) {
        const response = await axios.post(auth.agoraToken(), { channelName });
        return response.data;
    },
    async generateCallToken(data = {}) {
        const response = await axios.post(auth.generateToken(), { ...data });
        return response.data;
    },

    async callUser(data) {
        const response = await axios.post(auth.agoraCallUser(), data);
        return response.data;
    },
    async contactUs(data) {
        const response = await axios.file(auth.contactUs(), data);
        return response.data;
    },
    async resetValidateAccount(credentials) {
        const response = await axios.put(auth.reset(), credentials);
        return response.data;
    },

    async resetValidateAccount(credentials) {
        const response = await axios.put(auth.reset(), credentials);
        return response.data;
    },

    async validatePhone(phoneNumber, code = '') {
        const response = await axios.put(auth.validatePhoneNumber(), { phone_number: phoneNumber, code });
        return response.data;
    },

    async sendPhoneValidationCode(phoneNumber, mode = 'sms') {
        const response = await axios.get(auth.validatePhoneNumber(), { phone_number: phoneNumber, mode });
        return response.data;
    },

    async completeMedicalCenter(id, data, token) {
        const response = await axios.post(auth.completeMedical(id), data);
        return response.data;
    },

    async sendNewPassword(credentials) {
        const response = await axios.put(auth.resetGetAccount(), credentials);
        return response.data;
    },
    async resetResendCode(credentials) {
        const response = await axios.post(auth.resetResendCode(), credentials);
        return response.data;
    },
    async getResetAccount(token) {
        const response = await axios.get(auth.resetGetAccount(), { rToken: token });
        return response.data;
    },
    async disconnect() {
        const response = await axios.post(auth.logout());
        return response.data;
    },
    async getProfile() {
        const response = await axios.get(auth.profile());
        return response.data;
    },
    async getAllUsers(page, filters = null) {
        const response = await axios.post(users.get(page), filters);
        return response.data;
    },
    async createUser(data) {
        const response = await axios.post(users.create(), data);
        return response.data;
    },
    async createAccount(user) {
        const response = await axios.post(auth.register(), user);
        return response.data;
    },

    async add(user) {
        const response = await axios.post(users.index(), user);
        return response.data;
    },

    async saveFile(data) {
        const response = await axios.file(auth.files(), data);
        return response.data;
    },

    async getRequest() {
        const response = await axios.get(auth.request());
        return response.data;
    },

    async getFiles() {
        const response = await axios.get(auth.files());
        return response.data;
    },

    async update(user) {
        const response = await axios.put(auth.profile(), user);
        return response.data;
    },

    async getStatistics() {
        const response = await axios.get(auth.statistics());
        return response.data;
    },

    async updatePassword(data) {
        const response = await axios.put(auth.updatePassword(), data);
        return response.data;
    },
    async intermediaryRequest() {
        const response = await axios.put(auth.intermediary());
        return response.data;
    },

    async remove(id) {
        const response = await axios.delete(api.users(), {
            data: {
                id
            }
        });
        return response.data;
    },


    async logout() {
        const response = await axios.post(auth.disconnect());
        return response.data;
    },

    async profile() {
        const response = await axios.post(auth.profile());
        return response.data;
    },

    async contacts() {
        const response = await axios.post(auth.userContacts());
        return response.data;
    },

    async addContact(data) {
        const response = await axios.post(auth.addContact(), data);
        return response.data;
    },
    async getAddress() {
        const response = await axios.post(auth.getAddress());
        return response.data;
    },

    async removeContact(data) {
        const response = await axios.post(auth.removeContact(), data);
        return response.data;
    },
    async becomeInterlocutor(data) {
        const response = await axios.post(auth.intermediary(), data);
        return response.data;
    },
    async getInterlocutorProfile(data) {
        const response = await axios.get(auth.intermediaryProfile(), data);
        return response.data;
    },
    async getInterlocutorOrders() {
        const response = await axios.get(auth.intermediaryOrders());
        return response.data;
    },
    async getInterlocutorStatistics(data) {
        const response = await axios.get(auth.intermediaryStatistics(), data);
        return response.data;
    },
    async interlocutorProfileAttacheFile(data) {
        console.log(data);
        const response = await axios.file(auth.intermediaryFiles(), data);
        return response.data;
    },
    async roles() {
        const response = await axios.post(auth.roles());
        return response.data.roles;
    },
    async updateTransaction(method, invoiceId, transactionId) {
        const response = await axios.get(transactions.update(method, invoiceId, transactionId));
        return response.data;
    },

    async updateAvatar(data) {
        const response = await axios.post(auth.avatar(), data);
        return response.data;
    },

    async updateProfile(data) {
        const response = await axios.put(auth.update(), data);
        return response.data;
    },

    setToken(token) {
        /// axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },

    async statistics() {
        const response = await axios.get(auth.statistics());
        return response.data;
    }
};
