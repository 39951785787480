<template>
    <div class="w-full h-full transition-all" v-if="isCallLive">
        <section
            class="content-section rounded-lg w-full h-full overflow-hidden border-5 border-slate-300"
            v-if="video"
        >
            <VideoComponent :call="call" :participant="localParticipant" />
        </section>
        <section
            class="content-section h-full rounded-lg overflow-hidden border-4 border-slate-300"
            v-else-if="!localParticipant"
        >
            <div
                class="w-full h-full flex bg-primary bg-opacity-50 justify-center items-center"
            >
                <div
                    class="h-[80px] w-[80px] rounded-full font-bold bg-primary text-white flex justify-center items-center"
                >
                    <h1 class="text-2xl">{{ initials }}</h1>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { computed, onMounted, ref, defineComponent, watch } from "vue";
import VideoComponent from "./video.vue";
export default defineComponent({
    components: { VideoComponent },
    props: {
        video: Boolean,
        call: Object,
        member: Object,
        isBackstage: Boolean,
        isCallLive: Boolean,
        localParticipant: Object,
    },
    setup(props, { emit }) {
        const initials = computed(() =>
            (props.member?.name ?? "")
                ?.match(/(^\S\S?|\b\S)?/g)
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toUpperCase()
        );
        // const thumbnails = computed(() => props.call.state.thumbnails);
        // const imageUrl = ref(thumbnails.image_url);

        function startBroadcast() {
            emit("startBroadcast");
        }

        const buttonText = computed(() => {
            return props.isBackstage ? "Go live" : "End broadcast";
        });

        const endCall = async () => {
            await call.value?.endCall();
            localParticipantSub.value?.unsubscribe();
            isBackstageSub.value?.unsubscribe();

            call.value = undefined;
        };

        watch(
            () => props.call,
            (val) => {
                props.call?.state.thumbnails$.subscribe((thumbnails) => {
                    // imageUrl.value = thumbnails.image_url;
                    // console.log("imageUrl.value", imageUrl.value);
                });
            }
        );

        watch(
            () => props.localParticipant,
            (val) => {
                console.log(
                    "props.localParticipant?.image",
                    props.localParticipant,
                    props.member
                );
            }
        );

        onMounted(() => {
            console.log(
                "props.localParticipant?.image 1",
                props.localParticipant,
                props.isCallLive
            );
        });

        return {
            buttonText,
            initials,
            startBroadcast,
            endCall,
        };
    },
});
</script>
<style>
.button-row {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;
}
</style>
