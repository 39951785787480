<template>
    <TransitionRoot
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
        appear
        v-if="isOpen"
        :show="isOpen"
    >
        <Dialog as="div" @close.prevent.stop="closeModal" class="relative z-30">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-50" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex min-h-full items-center justify-center p-4 text-center"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            :class="`${width} max-w-md2 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all`"
                        >
                            <DialogTitle
                                as="div"
                                class="text-xl flex items-center justify-between font-medium leading-9 text-gray-900 px-5 py-4"
                            >
                                <slot name="title">
                                    <h3 class="text-md font-ibm text-slate-800">
                                        {{ title }}
                                    </h3>
                                </slot>
                                <div class="flex justify-end">
                                    <div
                                        @click.prevent.stop="handleCancel"
                                        class="bg-primary4 w-10 flex rounded justify-center items-center"
                                    >
                                        <i
                                            class="bi bi-x text-2xl text-white"
                                        ></i>
                                    </div>
                                </div>
                            </DialogTitle>
                            <div
                                class="mt-2 px-6 overflow-y-scroll"
                                style="max-height: 500px"
                            >
                                <slot />
                            </div>

                            <div
                                class="flex justify-between items-center mt-0 border-t border-slate-100 py-4 px-5"
                            >
                                <div class="flex justify-start">
                                    <slot name="left"></slot>
                                </div>
                                <div class="text-md flex justify-end">
                                    <button
                                        class="text-danger px-3 py-2"
                                        type="reset"
                                        :disabled="disableCancel"
                                        @click="handleCancel"
                                    >
                                        Fermer
                                    </button>
                                    <slot name="buttons" />
                                    <button
                                        v-if="!hideValidate"
                                        class="ml-4 rounded bg-primary text-primary bg-opacity-25 hover:bg-opacity-25 px-3 py-2"
                                        type="submit"
                                        variant="primary"
                                        :disabled="disableValidate"
                                        @click="handleValidate"
                                    >
                                        {{ validateText ?? "Valider" }}
                                    </button>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from "@headlessui/vue";

export default {
    components: {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        disableCancel: {
            type: Boolean,
            default: false,
        },
        hideValidate: {
            type: Boolean,
            default: false,
        },
        disableValidate: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: "w-3/5",
        },
        title: {
            type: String,
            default: "Modal",
        },
        validateText: {
            type: String,
            default: null,
        },
    },
    emits: ["cancel", "submit"],
    setup(props, { emit }) {
        const isOpen = ref(false);

        const closeModal = (value = false) => {
            // isOpen.value = value;
            emit("cancel", value);
        };

        const openModal = () => {
            isOpen.value = true;
        };

        const handleCancel = () => {
            closeModal(false);
        };

        const handleValidate = () => {
            emit("submit");
        };

        watch(
            () => props.isVisible,
            (value) => {
                isOpen.value = value;
            }
        );

        onMounted(() => {
            isOpen.value = props.isVisible;
            console.log("opened", props.isVisible);
        });

        return { isOpen, openModal, closeModal, handleCancel, handleValidate };
    },
};
</script>
