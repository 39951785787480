<template>
    <nav
        class="bg-primary w-full border-gray-200 dark:bg-gray-900 sticky top-0 h-20 z-50 flex justify-center account-header"
    >
        <div
            class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-12 w-full"
        >
            <a href="/" class="flex items-center">
                <img
                    alt="Allô Doto Image"
                    class="logo__image w-28"
                    src="/images/logo_white.svg"
                />
            </a>

            <div
                class="items-center justify-between xs:w-full sm:w-full md:flex md:w-auto md:order-0"
                id="navbar-user"
            >
                <ul
                    class="flex sm:flex-col xs:flex-colfont-medium md:p-0 bg-primary rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
                >
                    <li class="py-1">
                        <a
                            target="_blank"
                            href="/faq"
                            class="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500"
                            aria-current="page"
                            ><i class="la la-question-circle mr-1"></i>Aide
                        </a>
                    </li>
                    <li
                        :class="`py-1 px-2 hover:bg-opacity-25 cursor-pointer ${
                            [
                                'patient.appointments',
                                'patient.appointment',
                            ].includes($route.name)
                                ? 'bg-slate-800 bg-opacity-25'
                                : ''
                        }`"
                    >
                        <router-link
                            to="/account/appointments"
                            class="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                            >Mes rendez-vous</router-link
                        >
                    </li>
                    <li
                        :class="`py-1 px-2 hover:bg-opacity-25 cursor-pointer ${
                            pathName == '/account/documents'
                                ? 'bg-slate-800 bg-opacity-25'
                                : ''
                        }`"
                    >
                        <router-link
                            to="/account/documents"
                            class="block py-2 text-white rounded pl-2 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                            >Mes documents
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="flex">
                <Menu as="div" class="relative inline-block text-left">
                    <div>
                        <MenuButton
                            class="inline-flex w-full justify-center items-center rounded-md px-2 py-1 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
                            ><p class="mr-2 font-medium">
                                {{ user?.full_name }}
                            </p>
                            <Avatar
                                :image="user?.avatar"
                                class="bg-black/20 shadow-none bg-cover overflow-hidden"
                            />
                        </MenuButton>
                    </div>

                    <transition
                        enter-active-class="transition duration-100 ease-out"
                        enter-from-class="transform scale-95 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-in"
                        leave-from-class="transform scale-100 opacity-100"
                        leave-to-class="transform scale-95 opacity-0"
                    >
                        <MenuItems
                            class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                        >
                            <div class="px-1 py-1">
                                <MenuItem v-slot="{ active }">
                                    <button
                                        :class="[
                                            active
                                                ? 'bg-slate-400/20 text-primary'
                                                : 'text-gray-900',
                                            'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                        ]"
                                        @click="
                                            $router.push({
                                                name: 'patient.profile',
                                            })
                                        "
                                    >
                                        <span class="mr-2">
                                            <i
                                                class="las la-user-circle text-lg"
                                            ></i>
                                        </span>
                                        Profile
                                    </button>
                                </MenuItem>
                                <MenuItem
                                    v-slot="{ active }"
                                    v-if="roleMin <= 5"
                                >
                                    <button
                                        :class="[
                                            active
                                                ? 'bg-slate-400/20 text-primary'
                                                : 'text-gray-900',
                                            'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                        ]"
                                        @click="goToApp()"
                                    >
                                        <span class="mr-2">
                                            <i
                                                class="las la-stethoscope text-lg"
                                            ></i>
                                        </span>
                                        Espace
                                        {{ user.roles[0]?.name ?? "Manager" }}
                                    </button>
                                </MenuItem>
                            </div>

                            <div class="px-1 py-1 border-t border-primary/20">
                                <MenuItem v-slot="{ active }">
                                    <button
                                        :class="[
                                            active
                                                ? 'bg-slate-400/20 text-primary'
                                                : 'text-gray-900',
                                            'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                        ]"
                                        @click.prevent="logout"
                                    >
                                        <span
                                            class="bg-danger/20 mr-2 px-1 py-1 rounded"
                                        >
                                            <i
                                                class="las la-sign-out-alt text-danger text-md"
                                            ></i>
                                        </span>

                                        Me Déconnecter
                                    </button>
                                </MenuItem>
                            </div>
                        </MenuItems>
                    </transition>
                </Menu>
                <button
                    data-collapse-toggle="navbar-user"
                    type="button"
                    class="inline-flex md:hidden lg:hidden xl:hidden items-center text-white p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    aria-controls="navbar-user"
                    aria-expanded="false"
                >
                    <span class="sr-only">Open main menu</span>
                    <svg
                        class="w-5 h-5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 17 14"
                    >
                        <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M1 1h15M1 7h15M1 13h15"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </nav>
</template>

<script>
import { computed, ref, defineComponent, onBeforeMount, onMounted } from "vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import Badge from "primevue/badge";
import Menubar from "primevue/menubar";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
// import Menu from "primevue/menu";
import Button from "primevue/button";
import Avatar from "primevue/avatar";
import Storage, { storageKeys } from "../../services/Storage";
import HeartIcon from "#/images/heart-icon.svg";
import logoutIcon from "#/images/logout-icon.svg";
export default defineComponent({
    components: {
        Menubar,
        Menu,
        Avatar,
        Button,
        Badge,
        MenuButton,
        MenuItems,
        MenuItem,
    },
    setup() {
        const route = useRoute();
        const store = useStore();
        const user = computed(() => store.getters["auth/user"]);
        const roleMin = computed(() =>
            Math.min(...user.value.roles.map((x) => x.level))
        );
        const pathName = computed(() => route.path);
        const menu = ref();
        const items = computed(() => [
            {
                label: "Mon espace",
                icon: "la la-tachometer-alt",
                url: "/account",
            },
            {
                label: "Profile",
                icon: "la la-user ",
                url: "/account/profile",
            },
        ]);
        const toggle = (event) => {
            menu.value.toggle(event);
        };

        const goToApp = () => {
            window.location.replace("/app");
        };
        const logout = () => {
            store.dispatch("auth/logout", {
                onSuccess: (user) => {
                    Storage.remove(storageKeys.authToken);
                    window.location.replace("/");
                },
            });
        };
        onBeforeMount(() => {
            // isLoading.value = true;
            store.dispatch("auth/initApp", {
                onSuccess: () => {
                    store.dispatch("auth/getUser", {
                        onSuccess: (user) => {
                            // isLoading.value = false;
                        },
                    });
                    // next();
                },
                onError: () => {
                    // isLoading.value = false;
                },
            });
        });

        onMounted(() => {});

        return {
            user,
            toggle,
            logout,
            goToApp,
            menu,
            roleMin,
            items,
            pathName,
            HeartIcon,
            logoutIcon,
        };
    },
});
</script>
<style lang="scss">
.account-header {
    .p-avatar {
        img {
            height: 100%;
        }
    }
}
</style>
