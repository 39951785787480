<template>
    <div class="w-full my-6">
        <div class="w-full">
            <div class="w-full">
                <div
                    class="rounded-sm bg-white flex flex-col p-5 mb-5 w-full overflow-y-scroll"
                >
                    <div
                        class="flex flex-row items-center justify-between mb-3"
                    >
                        <div>
                            <h4
                                class="card-title font-ibm font-semibold text-slate-800"
                            >
                                Les actes pratiqués
                            </h4>
                        </div>
                        <button
                            @click="startNewProcedure"
                            class="flex justify-center font-semibold items-center border-none rounded-sm bg-opacity-25 hover:bg-opacity-50 bg-doto-100 text-doto-100 px-2 py-2"
                        >
                            <icon name="plus" class=""></icon>
                            Ajouter un acte
                        </button>
                    </div>
                    <div v-if="procedures.length > 0 && !isLoading">
                        <div>
                            <div md="12" class="table-responsive">
                                <table
                                    striped
                                    outlined
                                    class="table table-striped font-ibm"
                                    hover
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                class="whitespace-nowrap font-ibm font-medium text-sm"
                                            >
                                                Nom
                                            </th>

                                            <th
                                                class="whitespace-nowrap font-ibm font-medium text-sm"
                                            >
                                                Spécialité
                                            </th>

                                            <th class="whitespace-nowrap">
                                                Date de création
                                            </th>
                                            <!-- <th class="whitespace-nowrap">Dernière conn.</th> -->
                                            <th class="whitespace-nowrap"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            v-for="proc in procedures"
                                            :key="proc.id"
                                        >
                                            <td>
                                                <p
                                                    class="font-ibm text-slate-700 text-sm font-semibold rounded dark:bg-pink-200 dark:text-pink-900"
                                                >
                                                    {{ proc.name }}
                                                </p>
                                            </td>
                                            <td
                                                class="font-ibm text-sm text-slate-700"
                                            >
                                                {{
                                                    proc?.specialty?.name ?? "-"
                                                }}
                                            </td>
                                            <td
                                                class="font-ibm text-sm text-slate-700"
                                            >
                                                {{
                                                    proc.created_at
                                                        ? moment(
                                                              proc.created_at
                                                          ).format("DD/MM/YYYY")
                                                        : "-"
                                                }}
                                            </td>

                                            <td>
                                                <div
                                                    class="flex justify-end font-ibm"
                                                >
                                                    <button
                                                        class="bg-warning bg-opacity-25 mr-1 px-2 py-1 rounded text-warning"
                                                        size="sm"
                                                        @click="
                                                            displayDetails(proc)
                                                        "
                                                    >
                                                        <i
                                                            class="las la-eye m-0"
                                                        ></i>
                                                    </button>
                                                    <button
                                                        class="bg-primary bg-opacity-25 mr-1 px-2 py-1 rounded text-primary"
                                                        size="sm"
                                                        @click="
                                                            startNewProcedure(
                                                                proc
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="las la-pen m-0"
                                                        ></i>
                                                    </button>
                                                    <button
                                                        class="px-2 py-1 bg-danger bg-opacity-25 rounded text-danger"
                                                        size="sm"
                                                        @click="
                                                            startDeletion(proc)
                                                        "
                                                    >
                                                        <i
                                                            class="la la-trash m-0"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div
                        v-else-if="isLoading"
                        class="flex w-full h-full justify-center items-center"
                    >
                        <Loader class="w-20 text-slate-500" />
                    </div>

                    <div v-else class="p-3 h-full">
                        <div
                            class="flex flex-col justify-center items-center h-full"
                        >
                            <img class="w-1/4" src="/images/no-result.svg" />
                            <h4
                                class="text-primary font-medium my-3 text-xl font-ibm"
                            >
                                Aucune alerte
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SampleModal
            :is-visible="showDetailedProcedure"
            title="Détails"
            id="new-procedure"
            width="w-2/5"
            :hideValidate="true"
            @close="cancelProcedureDetails()"
            @cancel="cancelProcedureDetails()"
        >
            <div class="mb-2">
                <div class="my-2 flex">
                    <span class="mr-2 font-semibold">Nom: </span>
                    <p>{{ currentProcedure.name }}</p>
                </div>
                <div class="my-2 flex">
                    <span class="mr-2 font-semibold">Spécialité: </span>
                    <p>{{ currentProcedure?.specialty?.name ?? "-" }}</p>
                </div>

                <div
                    class="my-2 flex"
                    v-if="
                        currentProcedure?.price_min &&
                        currentProcedure?.price_max
                    "
                >
                    <span class="mr-2 font-semibold">Pricing: </span>
                    <p>
                        {{
                            currentProcedure?.price_max ==
                            currentProcedure?.price_min
                                ? `${currentProcedure?.price_min} f CFA`
                                : `${currentProcedure?.price_min} f CFA - ${currentProcedure?.price_max} f CFA`
                        }}
                    </p>
                </div>

                <div class="my-2 flex">
                    <span class="mr-2 font-semibold">Description: </span>
                    <p>{{ currentProcedure?.description ?? "-" }}</p>
                </div>

                <div class="my-2 flex">
                    <Badge
                        v-if="currentProcedure.modes.includes('in_office')"
                        class="text-center font-normal"
                        severity="secondary"
                    >
                        <i class="la la-hospital text-md"></i>En cabinet</Badge
                    >
                    <Badge
                        v-if="currentProcedure.modes.includes('on_video')"
                        severity="success"
                        class="mx-2 text-center font-normal"
                    >
                        <i class="la la-video text-md mr-1"></i>Vidéo
                        Call</Badge
                    >
                </div>
            </div>
        </SampleModal>
        <SampleModal
            :is-visible="showNewProcedure"
            :title="
                !currentProcedure?.id ? 'Ajouter un acte' : 'Modifier un acte'
            "
            id="new-country"
            width="w-2/5"
            @close="cancelProcedure()"
            @cancel="cancelProcedure()"
            @submit="
                currentProcedure?.id ? updateProcedure() : addNewProcedure()
            "
        >
            <div class="mb-2">
                <div class="my-2 w-100 mb-10">
                    <form>
                        <div class="mb-2">
                            <label
                                class="block capitalize tracking-wide text-slate-600 text-xs font-semibold text-left outline-none mb-1"
                                for="grid-state"
                            >
                                Nom
                            </label>
                            <div class="h-10">
                                <Field
                                    name="name"
                                    v-slot="{ field }"
                                    v-model="currentProcedure.name"
                                >
                                    <input
                                        placeholder="Entrer le nom du pays"
                                        name="name"
                                        focus
                                        v-bind="field"
                                        class="bg-white h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    />
                                </Field>
                            </div>
                        </div>

                        <div class="w-full mb-2">
                            <label
                                class="block capitalize tracking-wide text-slate-600 text-xs font-semibold text-left outline-none mb-1"
                                >Spécialité</label
                            >
                            <Select
                                v-model="currentProcedure.specialty"
                                class="w-full h-10"
                                name="specialty"
                                placeholder="Spécialité"
                                :multiple="false"
                                :value="currentProcedure.specialty"
                                :options="allSpecialties"
                            />
                        </div>

                        <div class="mb-2 mt-2 flex">
                            <div class="w-1/2 pr-2">
                                <label
                                    class="block capitalize tracking-wide text-slate-600 text-xs font-semibold text-left outline-none mb-1"
                                    for="grid-state"
                                >
                                    Prix minumum
                                </label>
                                <div class="h-10">
                                    <Field
                                        name="price_min"
                                        v-slot="{ field }"
                                        v-model="currentProcedure.price_min"
                                    >
                                        <input
                                            placeholder="Entrer le prix minumum"
                                            name="price_min"
                                            focus
                                            v-bind="field"
                                            class="bg-white h-10 rounded border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                        />
                                    </Field>
                                </div>
                            </div>

                            <div class="w-1/2 pl-2">
                                <label
                                    class="block capitalize tracking-wide text-slate-600 text-xs font-semibold text-left outline-none mb-1"
                                    for="grid-state"
                                >
                                    Prix maximum
                                </label>
                                <div class="h-10">
                                    <Field
                                        name="price_max"
                                        v-slot="{ field }"
                                        v-model="currentProcedure.price_max"
                                    >
                                        <input
                                            placeholder="Entrer le prix maximum"
                                            name="price_max"
                                            focus
                                            v-bind="field"
                                            class="bg-white h-10 rounded border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                        />
                                    </Field>
                                </div>
                            </div>
                        </div>

                        <div class="my-2">
                            <h3>Modes de consultations possibles</h3>
                            <div class="flex">
                                <div
                                    class="flex align-items-center mb-2 mt-2 mr-4"
                                >
                                    <Checkbox
                                        class=""
                                        v-model="currentProcedure.modes"
                                        inputId="modes"
                                        name="modes"
                                        value="in_office"
                                        :binary="false"
                                    />
                                    <label for="ingredient4" class="ml-2">
                                        En cabinet
                                    </label>
                                </div>
                                <div class="flex align-items-center mb-2 mt-2">
                                    <Checkbox
                                        class=""
                                        v-model="currentProcedure.modes"
                                        inputId="modes"
                                        name="modes"
                                        value="on_video"
                                        :binary="false"
                                    />
                                    <label for="ingredient4" class="ml-2">
                                        En Visio
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="w-full flex flex-col">
                            <label
                                class="block capitalize tracking-wide text-slate-600 text-xs font-semibold text-left outline-none mb-1"
                                for="grid-state"
                            >
                                Description
                            </label>
                            <textarea
                                v-model="currentProcedure.description"
                                name="description"
                                id="currentProcedure"
                                class="resize-none bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                rows="4"
                                colspan="5"
                            ></textarea>
                        </div>
                    </form>
                </div>
            </div>
        </SampleModal>
        <popup
            message="Êtes-vous sûre de vouloir supprimer ?"
            :isVisible="canDelete"
            @submit="removeProcedure"
            @cancel="canDelete = false"
            @close="canDelete = false"
        ></popup>
    </div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import SampleModal from "@/components/Modal/SampleModal.vue";
import { Form, Field } from "vee-validate";
import Select from "@/components/Select/index.vue";
import { TYPE } from "vue-toastification";
import Badge from "primevue/badge";
import Checkbox from "primevue/checkbox";
export default {
    components: { SampleModal, Field, Select, Checkbox, Badge },
    setup() {
        const store = useStore();
        const procedures = ref([]);
        const isLoading = ref(false);
        const canDelete = ref(false);
        const showDetailedProcedure = ref(false);
        const pagination = ref();
        const showNewProcedure = ref(false);
        const currentProcedure = ref(null);
        const allSpecialties = computed(() =>
            store.getters["config/specialties"].map((x) => ({
                id: x.id,
                name: x.name,
            }))
        );
        const portfolio = computed(() => store.getters["auth/portfolio"]);

        const getSpecialties = (page = 1) => {
            store.dispatch("config/getSpecialties", {});
        };

        const getProcedures = () => {
            isLoading.value = true;
            store.dispatch("config/getProcedures", {
                data: {},
                onSuccess: (res) => {
                    procedures.value = [...res.data];
                    pagination.value = {
                        from: res.from,
                        to: res.to,
                        total: res.total,
                    };
                    isLoading.value = false;
                },
                onError: () => {},
            });
        };

        const addNewProcedure = () => {
            store.dispatch("config/addProcedures", {
                data: {
                    // id: currentProcedure.value.id,
                    portfolio_id: portfolio.value.id,
                    price_max: currentProcedure.value?.price_max,
                    price_min: currentProcedure.value?.price_min,
                    description: currentProcedure.value?.description ?? "",
                    name: currentProcedure.value.name,
                    modes: currentProcedure.value.modes,
                    specialty_id: currentProcedure.value?.specialty?.id,
                },
                onSuccess: () => {
                    currentProcedure.value = {};
                    showNewProcedure.value = false;

                    store.dispatch("medical/displayToast", {
                        message: "L'acte ajouté avec succès",
                        context: "Actes pratiqués",
                        type: TYPE.SUCCESS,
                        icon: "la-user",
                    });
                },
                onError: () => {},
            });
        };

        const startDeletion = (data) => {
            currentProcedure.value = { ...data };
            canDelete.value = true;
        };

        const cancelProcedureDetails = () => {
            showDetailedProcedure.value = false;

            currentProcedure.value = {};
        };

        const removeProcedure = () => {
            store.dispatch("config/removeProcedures", {
                id: currentProcedure.value.id,
                onSuccess: () => {
                    getProcedures();
                    currentProcedure.value = {};
                    canDelete.value = false;

                    store.dispatch("medical/displayToast", {
                        message: "L'acte supprimé avec succès",
                        context: "Actes pratiqués",
                        type: TYPE.SUCCESS,
                        icon: "la-user",
                    });
                },
                onError: () => {},
            });
        };

        const updateProcedure = () => {
            store.dispatch("config/editProcedures", {
                id: currentProcedure.value.id,
                data: {
                    ...currentProcedure.value,
                    portfolio_id: portfolio.value.id,
                },
                onSuccess: () => {
                    getProcedures();
                    showNewProcedure.value = false;

                    currentProcedure.value = {};

                    store.dispatch("medical/displayToast", {
                        message: "L'acte modifié avec succès",
                        context: "Actes pratiqués",
                        type: TYPE.SUCCESS,
                        icon: "la-user",
                    });
                },
                onError: () => {},
            });
        };

        const displayDetails = (data) => {
            console.log(data);

            currentProcedure.value = { ...data };
            showDetailedProcedure.value = true;
        };

        const cancelProcedure = () => {
            showNewProcedure.value = false;
            currentProcedure.value = {};
        };

        const startNewProcedure = (data = {}) => {
            currentProcedure.value = { ...data };
            showNewProcedure.value = true;
        };

        onMounted(() => {
            getProcedures();
            getSpecialties();
        });

        return {
            currentProcedure,
            showNewProcedure,
            startNewProcedure,
            isLoading,
            procedures,
            canDelete,
            showDetailedProcedure,
            startDeletion,
            addNewProcedure,
            cancelProcedure,
            moment,
            displayDetails,
            cancelProcedureDetails,
            removeProcedure,
            updateProcedure,
            allSpecialties,
        };
    },
};
</script>
<style lang="scss">
.p-highlight {
    background: transparent;
    .p-checkbox-box {
        @apply border border-primary01 bg-white text-primary01;
        .p-icon {
            @apply text-primary01;
        }
    }
}
</style>
@/resources/components/ui/badge @/resources/js/components-ui/ui/badge
