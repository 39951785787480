<template>
    <Head>
        <meta name="robots" content="index,follow" />
        <title>Allô Doto - Politique de confidentialité</title>
        <meta
            head-key="description"
            name="description"
            content="Politique de confidentialité de Allô Doto. Découvrez comment nous protégeons vos données"
        />
    </Head>
    <app-bar></app-bar>

    <div class="w-full flex justify-center pt-10 pb-20 bg-color" id="policy">
        <div class="w-3/5 xs:w-11/12 text-justify">
            <div class="flex items-center justify-center mt-5 mb-2">
                <img src="/images/section_icon.svg" class="w-14" alt="" />
            </div>
            <div class="text-center my-10">
                <h3 class="text-4xl text-tertiary2 font-semibold text-center">
                    Politique de confidentialité
                </h3>
                <span class="text-sm italic text-opacity-60 text-tertiary2"
                    >Dernière mise à jour le
                    <strong>10 juillet 2023</strong></span
                >
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg">
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p>
                        Cette Politique de Confidentialité définit la manière
                        dont
                        <a target="_blank" href="https://habilis.bj">Habilis</a>
                        Bénin (ci- après la Société) utilise et protège les
                        informations que nous recueillons lorsque vous utilisez
                        le site www.Allô Doto.com (ci-après le Site) et
                        bénéficiez des services de prise de contact en cas
                        d'urgence et de rendez-vous médicaux en ligne (ci-après
                        les Services).
                    </p>

                    <p class="py-4">
                        La protection de vos données personnelles est une
                        préoccupation constante pour nous, et la Société
                        s'engage donc par la présente à faire en sorte que :
                    </p>
                    <ul>
                        <li class="ml-2">
                            Seules les informations personnelles nécessaires au
                            bon fonctionnement du Service soient collectées
                        </li>
                        <li class="ml-2">
                            Vos informations privées soient sécurisées et
                            protégées
                        </li>
                    </ul>

                    <p class="py-4">
                        La Société se réserve la faculté de modifier la présente
                        Politique de Confidentialité à tout moment sans préavis.
                        Vous devez vérifier cette page de temps en temps pour
                        vous assurer que vous avez pleinement connaissance des
                        dispositions de cette Politique de Confidentialité et
                        que vous êtes en accord avec celles-ci. Si vous
                        n'acceptez pas ces dispositions, vous devez cesser
                        d'utiliser le Site et renoncer à bénéficier du (ou des)
                        Service(s) proposé(s).
                    </p>

                    <p class="py-4">
                        Cette Politique de Confidentialité est effective à
                        partir du 30 Juillet 2023.
                    </p>
                </div>
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3 class="text-lg text-tertiary2 font-semibold text-left py-3">
                    Pourquoi avons-nous besoin de certaines données personnelles
                    ?
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="pt-4">
                        <strong>Allô Doto</strong> est un Service qui permet à
                        tout Utilisateur de trouver un Professionnel de Santé et
                        de prendre rendez-vous en ligne avec l'un d'entre eux.
                    </p>

                    <p class="pt-4">
                        La collecte de certaines informations personnelles est
                        nécessaire pour organiser la mise en relation de
                        l'Utilisateur avec un Professionnel de Santé et
                        permettre à ce dernier de recevoir son Patient dans les
                        meilleures conditions. Par ailleurs, certaines de vos
                        données personnelles sont stockées pour vous permettre
                        de gérer vos rendez-vous, les modifier ou les annuler.
                    </p>
                    <p class="py-4">
                        Ainsi, pour pouvoir accéder au Service, l'Utilisateur
                        devra ouvrir un Compte personnel lors de la confirmation
                        de sa demande de rendez-vous. La création du compte est
                        subordonnée au choix d'un Nom d'utilisateur et d'un Mot
                        de passe (ci-après les Identifiants).
                    </p>
                </div>
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3 class="text-lg text-tertiary2 font-semibold text-left py-3">
                    Quel type d'informations recueillons-nous ?
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="py-4">
                        Dans le cadre de la mise en œuvre du Service,
                        l'Utilisateur sera amené à renseigner des données
                        personnelles le concernant. Parmi les données à
                        caractère personnel de l'Utilisateur que la Société
                        collecte auprès de lui peuvent figurer :
                    </p>
                    <ul class="list-disc ml-7">
                        <li>Ses noms et prénoms</li>
                        <li>Son adresse email</li>
                        <li>Son numéro de téléphone</li>
                        <li>Son age</li>
                        <li>Son poids</li>
                        <li>Son Âge</li>
                        <li>Son groupe sanguin</li>
                        <li>
                            des informations pouvant relevées de son dossier
                            médical
                        </li>
                    </ul>

                    <p class="py-4">
                        Notre Service peut inclure des enquêtes facultatives,
                        destinées à recueillir votre opinion sur votre
                        expérience sur notre Site, l'utilisation de notre
                        Service, ou votre opinion sur les Professionnels de
                        Sante que vous avez consultés.
                    </p>
                    <p class="py-4">
                        Nous sommes également susceptibles de répertorier vos
                        activités et préférences lorsque vous vous rendez sur
                        notre Site (voir la rubrique "Cookies" ci-dessous).
                    </p>
                </div>
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3 class="text-lg text-tertiary2 font-semibold text-left py-3">
                    A quoi servent les informations que nous recueillons ?
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="py-4">
                        Les données personnelles collectées visent à permettre
                        la mise en œuvre du Service de prise de rendez-vous en
                        ligne. Ces données sont nécessaires au bon
                        fonctionnement du Service et ne sont utilisées qu'aux
                        fins suivantes :
                    </p>
                    <ul>
                        <li>
                            Envoyer à l'Utilisateur des emails administratifs,
                            par exemple pour vous prévenir de la confirmation
                            d'un rendez-vous, de sa modification ou de son
                            annulation, et vous rappeler les rendez-vous que
                            vous avez programmés
                        </li>
                        <li>
                            Traiter les demandes reçues par le Service Client
                        </li>
                        <li>
                            Transmettre au Professionnel de Santé les
                            informations nécessaires à l'organisation et la
                            préparation du rendez-vous de l'Utilisateur
                        </li>
                        <li>
                            Permettre à l'Utilisateur de gérer à tout moment
                            l'ensemble de ses rendez-vous et interventions
                            demandées via un Compte personnel sécurisé
                        </li>
                        <li>
                            Procéder à des analyses et recherches internes dans
                            le but d'améliorer notre Site et notre Service
                        </li>
                    </ul>

                    <p class="py-4">
                        Notre Service peut inclure des enquêtes facultatives,
                        destinées à recueillir votre opinion sur votre
                        expérience sur notre Site, l'utilisation de notre
                        Service, ou votre opinion sur les Professionnels de
                        Sante que vous avez consultés.
                    </p>
                    <p class="py-4">
                        Nous sommes également susceptibles de répertorier vos
                        activités et préférences lorsque vous vous rendez sur
                        notre Site (voir la rubrique "Cookies" ci-dessous).
                    </p>
                </div>
            </div>
            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3 class="text-lg text-tertiary2 font-semibold text-left py-3">
                    Qui peut accéder aux informations que nous recueillons ?
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="py-4">
                        Certaines des informations personnelles collectées
                        pourront être transmises au Professionnel de Santé
                        auprès duquel vous avez effectué une demande de
                        rendez-vous afin de lui permettre d'organiser le
                        rendez-vous dans les meilleures conditions et de
                        préparer sa consultation / son intervention. Chaque
                        Professionnel de Santé destinataire d'informations
                        personnelles dans le cadre du Service s'engage en y
                        adhérant à garantir leur confidentialité, et ce dans le
                        respect du secret professionnel auquel il est soumis. La
                        Société s'engage à ne transmettre ces données à
                        caractère personnel à aucun autre tiers non autorisé.
                    </p>
                    <p class="py-4">
                        Dans le cadre de recherches technologiques, elles
                        peuvent être utilisées mais en respectant une procédure
                        en vue d'annonimiser l'utilisation de ces données en
                        respectant les textes en vigueur. (voir la rubrique
                        "Traitement des données personnelles")
                    </p>
                </div>
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3
                    class="text-lg text-tertiary2 font-semibold text-left py-3"
                    id="protection"
                >
                    Traitement des données personnelles
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="py-4">
                        Vous vous engagez à fournir des informations exactes
                        quant à vos coordonnées personnelles ainsi que toute
                        autre information nécessaire à l'accès aux Services du
                        Site et à mettre à jour régulièrement ces informations
                        si nécessaire.
                    </p>
                    <p class="py-4">
                        Au Bénin, conformément à l’article 378 de la Loi
                        N°2017-20 du 20 Avril 2018 portant code du numérique en
                        République du Bénin, relative à la protection des
                        personnes physiques à l'égard du traitement des données
                        à caractère personnel, vous bénéficiez d'un droit
                        d'accès et de rectification aux informations qui vous
                        concernent, que vous pouvez exercer en vous adressant à
                        dpo@habilis.bj ou en accédant à votre espace personnel
                        sur le site ou via l'application mobile. Vous pouvez
                        également, pour des motifs légitimes, vous opposer à ce
                        que les données qui vous concernent fassent l'objet d'un
                        traitement. Ce traitement a été notifié et autorisé par
                        la CNDP au titre du récépissé / autorisation N°
                        A-W-353/2017.
                    </p>
                </div>
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3 class="text-lg text-tertiary2 font-semibold text-left py-3">
                    Durées de conservation
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="py-4">
                        Toutes les données à caractère personnel collectées sont
                        conservées sous une forme permettant l'identification
                        des personnes concernées pendant une durée n'excédant
                        pas celle nécessaire à la réalisation des finalités pour
                        lesquelles elles sont collectées et pour lesquelles
                        elles sont traitées ultérieurement.
                    </p>
                    <p class="py-4">
                        Dans ce cadre, la collecte, le traitement et la durée de
                        conservation des données à caractère personnel par Allô
                        Doto ont fait l'objet, conformément à l’article 378 de
                        la Loi N°2017-20 du 20 Avril 2018 relative à la
                        protection des personnes physiques à l'égard du
                        traitement des données à caractère personnel, de
                        demandes d'autorisation préalable auprès de la CNDP et
                        sont assurées conformément au(x) autorisation(s)
                        préalable(s) ainsi délivré.
                    </p>
                    <p class="py-4">
                        A l'issue de la durée nécessaire à la réalisation des
                        finalités de collecte et de traitement des données à
                        caractère personnelle des utilisateurs, celles-ci sont
                        supprimées de façon définitive.
                    </p>
                    <p class="py-4">
                        Il est précisé que s'agissant des données relatives à la
                        santé des utilisateurs et communiquées via la plateforme
                        aux professionnels de santé, ces derniers fixent
                        eux-mêmes la durée de conservation desdites données,
                        Allô Doto n'agissant pas dans ce cadre comme responsable
                        du traitement.
                    </p>
                    <p class="py-4">
                        Pour toute question ou précision sur la durée de
                        conservation des données à caractère personnel pour
                        lesquelles Allô Doto n'est pas responsable du
                        traitement, nous vous suggérons de vous adresser
                        directement à votre professionnel de santé responsable
                        de traitement.
                    </p>
                </div>
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3 class="text-lg text-tertiary2 font-semibold text-left py-3">
                    Cookies
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="py-4">
                        Afin de faciliter l'accès au Service, la Société utilise
                        des cookies sur son Site. Les Cookies sont de petits
                        fichiers texte qu'un site Web envoie à votre ordinateur
                        pour enregistrer votre activité en ligne. Les cookies
                        permettent aux internautes de naviguer facilement sur le
                        Site et d'exécuter diverses fonctions. Les cookies
                        visent notamment à améliorer la fluidité du Service et à
                        permettre le fonctionnement optimal du Site.
                        L'Utilisateur est informé que lorsque ces derniers sont
                        désactivés, le Site pourrait ne pas fonctionner de
                        manière optimale ou que certaines fonctionnalités
                        pourraient ne pas être accessibles. A aucun moment, ces
                        cookies ne permettent à la Société d'identifier
                        personnellement l'Utilisateur. La durée de conservation
                        de ces cookies dans l'ordinateur de l'Utilisateur
                        n'excède pas un (1) mois. L'Utilisateur est cependant
                        informé qu'il a la faculté de s'opposer à
                        l'enregistrement de ces cookies et ce notamment en
                        configurant son navigateur Internet pour ce faire.
                    </p>
                </div>
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3 class="text-lg text-tertiary2 font-semibold text-left py-3">
                    Sécurité
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="py-4">
                        Nous prenons les mesures requises pour protéger vos
                        informations personnelles. Celles-ci comprennent la mise
                        en place de processus et procédures visant à minimiser
                        l'accès non autorisé à vos informations ou leur
                        divulgation et à leur hébergement sur des serveurs
                        sécurisés. Nous ne pouvons cependant pas garantir
                        l'élimination totale du risque d'usage abusif de vos
                        informations personnelles par des intrus. Protégez les
                        mots de passe de vos comptes et ne les communiquez à
                        personne. Vous devez nous contacter immédiatement si
                        vous découvrez une utilisation non autorisée de votre
                        mot de passe ou toute autre violation de sécurité.
                    </p>
                </div>
            </div>
            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3 class="text-lg text-tertiary2 font-semibold text-left py-3">
                    Liens vers des sites tiers
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="py-4">
                        Notre Site peut contenir des liens vers d'autres sites
                        tiers (par exemple des sites personnels de
                        Professionnels de Santé). Cependant, une fois que vous
                        avez utilisé ces liens pour quitter notre site, vous
                        devez noter que nous n'avons aucun contrôle sur ces
                        sites Internet tiers. Par conséquent, nous ne pouvons
                        pas être tenus responsables de la protection et la
                        confidentialité des informations que vous fournissez en
                        visitant ces sites car ces sites ne sont pas régis par
                        la présente Politique de Confidentialité. Vous devez
                        faire preuve de prudence et lire la déclaration de
                        confidentialité applicable au site en question.
                    </p>
                </div>
            </div>

            <div class="bg-white rounded-xl py-5 px-8 shadow-lg my-8">
                <h3 class="text-lg text-tertiary2 font-semibold text-left py-3">
                    Contacts
                </h3>
                <div class="text-opacity-75 text-tertiary2 font-normal">
                    <p class="py-4">
                        Vous pouvez nous contacter pour exercer vos droits
                        d'accès, de rectification et de suppression de vos
                        données personnelles, ou pour toute question concernant
                        notre Politique de Confidentialité.
                    </p>
                    <p class="py-4">
                        Pour cela, vous pouvez envoyer un mail à
                        <strong>dpo@habilis.bj</strong>
                    </p>
                    <p class="py-4">
                        N'oubliez pas de nous adresser les pièces justificatives
                        de votre identité.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <Footer></Footer>
</template>
<script>
import Footer from "@/components/footer/index.vue";
import { Head } from "@inertiajs/vue3";
export default {
    components: { Footer, Head },
    setup() {},
};
</script>
