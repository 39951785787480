<template>
    <div
        ref="parentContainer"
        class="rounded-lg p-3 flex bg-slate-300 bg-opacity-50 w-2/3 mt-5"
    >
        <div class="w-4/5">
            <div ref="videoContainer" :class="`w-full relative`">
                <BroadcastComponent
                    :class="`w-full h-full`"
                    :localParticipant="remoteParticipant"
                    :isBackStage="isBackstage"
                    :call="call"
                    :video="videoEnabled && remoteParticipant"
                    :isCallLive="isCallLive"
                    :member="actualMember"
                    @startBroadcast="startBroadcast"
                    :style="`height:${videoHeight}px !important;`"
                />
                <div
                    class="absolute top-0 mt-5 flex items-center justify-center w-full"
                    v-if="notificationMessage"
                >
                    <span
                        class="bg-black bg-opacity-50 rounded-xl px-3 py-1 text-warning"
                        >{{ notificationMessage }}</span
                    >
                </div>
                <div
                    v-if="isCallLive"
                    class="absolute bottom-0 flex w-full items-center justify-center mb-5"
                >
                    <div
                        :class="`w-[30px] h-[30px] rounded-full bg-${
                            audioEnabled ? 'black' : 'danger'
                        } bg-opacity${
                            audioEnabled ? '-50' : ''
                        } flex items-center justify-center mr-2 cursor-pointer`"
                        @click="toggleAudio"
                    >
                        <i
                            :class="`la la-microphone${
                                audioEnabled ? '' : '-slash'
                            } text-white text-lg`"
                        ></i>
                    </div>
                    <div
                        :class="`w-[30px] h-[30px] rounded-full bg-${
                            videoEnabled ? 'black' : 'danger'
                        } bg-opacity${
                            videoEnabled ? '-50' : ''
                        } flex items-center justify-center cursor-pointer`"
                        @click="toggleVideo"
                    >
                        <i
                            :class="`la la-video${
                                videoEnabled ? '' : '-slash'
                            } text-white text-lg`"
                        ></i>
                    </div>
                </div>
            </div>
            <section class="content-section">
                <!-- <VideoComponent :call="call" :participant="localParticipant" /> -->
                <div class="button-row">
                    <button
                        :class="`bg-${
                            isCallLive ? 'danger' : 'success'
                        } text-white px-3 py-2 rounded-lg border-none`"
                        @click="isCallLive ? stopBroadcast() : startBroadcast()"
                    >
                        {{ buttonText }}
                    </button>
                </div>
            </section>
        </div>
        <div class="w-1/5 flex flex-col items-end">
            <div class="w-11/12">
                <ViewerComponent
                    :call="call"
                    :remoteParticipant="localParticipant"
                    :member="actualMember"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { onMounted, ref, computed, onBeforeUnmount } from "vue";
import { StreamVideoClient } from "@stream-io/video-client";
import { useStore } from "vuex";
import VideoComponent from "./video.vue";
import BroadcastComponent from "./BroadcastComponent.vue";
import ViewerComponent from "./viewer.vue";
export default {
    components: { VideoComponent, BroadcastComponent, ViewerComponent },
    props: {
        centerId: { type: Number },
        callId: { type: String },
        appointment: {},
    },
    setup(props) {
        const store = useStore();
        const parentContainer = ref();
        const videoContainer = ref();
        const videoHeight = computed(() =>
            Math.round(((videoContainer.value?.clientWidth ?? 0) / 16) * 9)
        );

        const call = ref();
        const notificationMessage = ref(null);
        const isBackstage = ref(false);
        const videoEnabled = ref(false);
        const audioEnabled = ref(false);
        const isBackstageSub = ref();
        const localParticipant = ref();
        const remoteParticipant = ref();
        const localParticipantSub = ref();
        const remoteParticipantSub = ref();
        const availableCameraDevices = ref([]);
        const availableAudioDevices = ref([]);
        const isCallLive = computed(() => {
            return call.value && localParticipant.value;
        });
        const members = ref([]);
        const actualMember = computed(() =>
            members.value.find((x) => x.is_current_user)
        );
        const streamVideoClient = ref();

        const buttonText = computed(() => {
            return isBackstage.value
                ? "End broadcast"
                : "Lancer la consultation";
        });

        const toggleVideo = () => {
            call.value.camera.toggle();
        };

        const toggleAudio = () => {
            call.value.microphone.toggle();
        };
        const leaveStream = async () => {
            await call.value?.leave();
            remoteParticipantSub.value?.unsubscribe();

            call.value = undefined;
        };

        const startBroadcast = () => {
            getToken();
        };

        const stopBroadcast = () => {
            notificationMessage.value = null;
            leaveStream();
            call.value.endCall();
        };

        const createCall = async (members) => {
            const newCall = streamVideoClient.value.call(
                "default",
                props.callId
            );

            newCall
                .join({ create: true, data: { ring: true } })
                .then(async () => {
                    newCall.camera.enable();
                    newCall.microphone.enable();
                    videoEnabled.value = true;
                    audioEnabled.value = true;
                    newCall.getOrCreate({
                        ring: true,
                        data: {
                            members: members.map((x) => ({
                                ...x,
                                user_id: x.id,
                            })),
                        },
                    });
                });
            // await newCall.getOrCreate({
            //     data: {
            //         // starts_at: new Date(Date.now() + 500 * 1000),

            //         members: members.map((x) => ({ ...x, user_id: x.id })),
            //         settings_override: {
            //             audio: {
            //                 mic_default_on: false,
            //                 default_device: "speaker",
            //             },
            //             video: {
            //                 access_request_enabled: true,
            //                 camera_default_on: false,
            //                 target_resolution: { width: 400, height: 400 },
            //             },
            //         },
            //     },
            // });

            // Subscribe to the local participant
            localParticipantSub.value =
                newCall.state.localParticipant$.subscribe(
                    (updatedLocalParticipant) => {
                        localParticipant.value = updatedLocalParticipant;
                    }
                );

            // Subscribe to backstage property
            isBackstageSub.value = newCall.state.backstage$.subscribe(
                (backstage) => {
                    isBackstage.value = backstage;
                }
            );

            remoteParticipantSub.value =
                newCall.state.remoteParticipants$.subscribe(
                    (newRemoteParticipants) => {
                        if (
                            newRemoteParticipants &&
                            newRemoteParticipants.length > 0
                        ) {
                            remoteParticipant.value = newRemoteParticipants[0];
                        } else {
                            remoteParticipant.value = undefined;
                        }
                    }
                );

            // await call.update({
            //     custom: { color: "green" },
            //     settings_override: {
            //         recording: {
            //             mode: RecordSettingsRequestModeEnum.DISABLED,
            //         },
            //     },
            // });
            // Update the local call value
            call.value = newCall;
            console.log(call.value);
            startListening();
            // call.value.setViewport(parentContainer.value);
            // console.log("localParticipantSub.value", localParticipantSub.value);
        };

        const endCall = async () => {
            await call.value?.endCall();
            localParticipantSub.value?.unsubscribe();
            isBackstageSub.value?.unsubscribe();

            call.value = undefined;
        };

        const goLiveClicked = async () => {
            console.log("isBackstage.value", isBackstage.value);

            if (isBackstage.value) {
                await call.value?.goLive();
            } else {
                await call.value?.stopLive();
            }
        };

        const getToken = () => {
            store.dispatch("auth/generateCallToken", {
                data: {
                    center_id: props.centerId,
                    appointment_id: props.appointment.id,
                },
                onSuccess: (token, data) => {
                    const currentMember = data.find((x) => x.is_current_user);
                    streamVideoClient.value = new StreamVideoClient({
                        apiKey: "sbztz2u66w6c",
                        token: token,
                        user: {
                            id: currentMember.id,
                            name: props.appointment.medical_center.name,
                            image: `https://getstream.io/random_svg/?id=${currentMember.id}&name=${currentMember.avatar}`,
                        },
                    });
                    members.value = data;
                    createCall(data);
                },
                onError: () => {},
            });
        };

        const startListening = () => {
            call.value.camera.state.status$.subscribe((status) => {
                videoEnabled.value = status === "enabled";
            });

            call.value.microphone.state.status$.subscribe((status) => {
                audioEnabled.value = status === "enabled";
                notificationMessage.value = null;
            });

            call.value.microphone.state.speakingWhileMuted$.subscribe(
                (isSpeaking) => {
                    if (isSpeaking) {
                        notificationMessage.value =
                            "Votre micro est coupé, activez-le pour parler";
                        // console.log(`You're muted, unmute yourself to speak`);
                    }
                }
            );

            // call.camera.state.selectedDevice; // currently selected camera
            call.value.camera.listDevices().subscribe({
                next: (data) => {
                    availableCameraDevices.value = [...data];
                },
                error: (err) => {
                    console.log("call.value.camera", err);
                },
            });
        };

        onBeforeUnmount(() => {
            if (isCallLive.value) {
                if (
                    window.confirm(
                        "Une session live est en cours, voulez-vous vraiment la quitter ?"
                    )
                ) {
                    leaveStream();
                }
            }
        });

        onMounted(() => {});

        return {
            call,
            audioEnabled,
            videoEnabled,
            goLiveClicked,
            isBackstage,
            buttonText,
            localParticipant,
            remoteParticipant,
            streamVideoClient,
            actualMember,
            videoHeight,
            stopBroadcast,
            videoContainer,
            createCall,
            endCall,
            toggleVideo,
            toggleAudio,
            isCallLive,
            parentContainer,
            notificationMessage,
            startBroadcast,
        };
    },
};
</script>
<style lang="scss">
video {
    object-fit: contain;
}
.tabList {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabButton {
    --background-color: white;
    color: var(--vue-green);
    padding: 0.5rem 2rem;
    background: var(--background-color);
    border-radius: 0;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
}

.tabButton:hover {
    border: 1px solid var(--vue-green);
}

.activeTab {
    --background-color: var(--vue-green);
    color: white;
}
</style>
