import Lodash from 'lodash'
import Axios from 'axios';
import { Agent } from '@newrelic/browser-agent/loaders/agent'
import { Metrics } from '@newrelic/browser-agent/features/metrics'
import { PageViewEvent } from '@newrelic/browser-agent/features/page_view_event'
import { PageViewTiming } from '@newrelic/browser-agent/features/page_view_timing'
import { Ajax } from '@newrelic/browser-agent/features/ajax';
import { JSErrors } from '@newrelic/browser-agent/features/jserrors';
import { GenericEvents } from '@newrelic/browser-agent/features/generic_events';
import { SessionTrace } from '@newrelic/browser-agent/features/session_trace';
import { Spa } from '@newrelic/browser-agent/features/spa';
window._ = Lodash

try {
    console.log("Welcome !!!");

    const options = {
        info: { beacon: "bam.eu01.nr-data.net", errorBeacon: "bam.eu01.nr-data.net", licenseKey: "NRJS-c592d7d5e89b87ecec1", applicationID: "538686527", sa: 1 },
        loader_config: { accountID: "6454809", trustKey: "6454809", agentID: "538686527", licenseKey: "NRJS-c592d7d5e89b87ecec1", applicationID: "538686527" },
        init: { session_replay: { enabled: true, block_selector: '', mask_text_selector: '*', sampling_rate: 100.0, error_sampling_rate: 100.0, mask_all_inputs: true, collect_fonts: true, inline_images: false, inline_stylesheet: true, fix_stylesheets: true, mask_input_options: {} }, distributed_tracing: { enabled: true }, privacy: { cookies_enabled: true }, ajax: { deny_list: ["bam.eu01.nr-data.net"] } }
    }

    new Agent({
        ...options,
        features: [
            Metrics,
            PageViewEvent,
            PageViewTiming,
            JSErrors,
            Spa,
            SessionTrace,
            GenericEvents,
            Ajax
        ]
    })
} catch (error) {

}
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = Axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


window.onscroll
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */








window.addEventListener("scroll", function () {

    const [header] = document.getElementsByTagName('header')

    const isBlog = typeof window !== 'undefined' && window.location.host.includes('blog.')
    if (header && !isBlog) {
        if (window.pageYOffset > 0) {
            header?.classList.add("sticking");
        } else {
            header?.classList.remove("sticking");
        }
    }

});
