<template>
    <div class="flex w-full justify-center sticky top-0 z-50 xs:px-0 sm:px-0">
        <header
            class="w-full bg-white transition transition-all px-12 xs:px-5 sm:px-5"
            ref="target"
            :class="{ sticking }"
        >
            <nav class="border-gray-200 py-2.5 dark:bg-gray-800">
                <div class="flex flex-wrap justify-between items-center">
                    <a href="/" class="flex items-center">
                        <img
                            src="/images/allo_logo.svg"
                            class="w-32"
                            alt="AllôDoto Logo"
                        />
                    </a>
                    <div class="flex items-center lg:order-2">
                        <a
                            v-if="!user"
                            href="/login"
                            class="bg-primary text-white outline-none dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-md px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                        >
                            Connexion
                        </a>

                        <div v-else class="card flex justify-content-center">
                            <Button
                                type="button"
                                class="bg-white rounded text-primary font-medium font-ibm px-5 py-2 shadow-none focus:shadow-none"
                                @click="toggle"
                                aria-haspopup="true"
                                aria-controls="overlay_menu"
                            >
                                <Avatar
                                    :image="user.avatar"
                                    class="bg-primary border-2 border-primary"
                                    shape="circle"
                                />
                            </Button>
                            <Menu
                                ref="menu"
                                id="overlay_menu"
                                :model="items"
                                :popup="true"
                            >
                                <template #start>
                                    <button
                                        class="w-full p-link flex items-center p-2 pl-3 text-color hover:surface-200 border-noround text-blackp"
                                    >
                                        <div
                                            class="flex flex-col justify-center text-blackp"
                                        >
                                            <span
                                                class="font-medium font-ibm"
                                                >{{ user?.full_name }}</span
                                            >
                                            <span class="text-xs font-qs">{{
                                                user?.username
                                            }}</span>
                                        </div>
                                    </button>
                                </template>
                                <template #item="{ item, label, props }">
                                    <a
                                        class="flex text-blackp"
                                        v-bind="props.action"
                                        :href="item.url"
                                    >
                                        <span v-bind="props.icon" />
                                        <span v-bind="props.label">{{
                                            label
                                        }}</span>
                                        <Badge
                                            v-if="item.badge"
                                            class="ml-auto"
                                            :value="item.badge"
                                        />
                                    </a>
                                </template>
                                <template #end>
                                    <button
                                        class="w-full p-link flex items-center p-2 pl-4 text-color hover:surface-200"
                                        @click.prevent="logout"
                                    >
                                        <i class="la la-sign-out" />
                                        <span class="ml-2">Déconnexion</span>
                                    </button>
                                </template>
                            </Menu>
                            <transition
                                enter-active-class="transition duration-100 ease-out"
                                enter-from-class="transform scale-95 opacity-0"
                                enter-to-class="transform scale-100 opacity-100"
                                leave-active-class="transition duration-75 ease-in"
                                leave-from-class="transform scale-100 opacity-100"
                                leave-to-class="transform scale-95 opacity-0"
                            >
                                <MenuItems
                                    class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                                >
                                    <div class="px-1 py-1">
                                        <MenuItem v-slot="{ active }">
                                            <button
                                                :class="[
                                                    active
                                                        ? 'bg-slate-400/20 text-primary'
                                                        : 'text-gray-900',
                                                    'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                                ]"
                                            >
                                                <span class="mr-2">
                                                    <i
                                                        class="las la-user-circle text-lg"
                                                    ></i>
                                                </span>
                                                Profile
                                            </button>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }">
                                            <button
                                                :class="[
                                                    active
                                                        ? 'bg-slate-400/20 text-primary'
                                                        : 'text-gray-900',
                                                    'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                                ]"
                                            >
                                                <span class="mr-2">
                                                    <i
                                                        class="las la-user-md text-lg"
                                                    ></i>
                                                </span>
                                                Santé
                                            </button>
                                        </MenuItem>
                                    </div>

                                    <div
                                        class="px-1 py-1 border-t border-primary/20"
                                    >
                                        <MenuItem v-slot="{ active }">
                                            <button
                                                :class="[
                                                    active
                                                        ? 'bg-slate-400/20 text-primary'
                                                        : 'text-gray-900',
                                                    'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                                ]"
                                                @click.prevent="logout"
                                            >
                                                <span
                                                    class="bg-danger/20 mr-2 px-1 py-1 rounded"
                                                >
                                                    <i
                                                        class="las la-sign-out-alt text-danger text-md"
                                                    ></i>
                                                </span>

                                                Me Déconnecter
                                            </button>
                                        </MenuItem>
                                    </div>
                                </MenuItems>
                            </transition>
                        </div>
                        <button
                            data-collapse-toggle="mobile-menu-2"
                            type="button"
                            class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            aria-controls="mobile-menu-2"
                            aria-expanded="false"
                        >
                            <span class="sr-only">Open main menu</span>
                            <svg
                                class="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                            <svg
                                class="hidden w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>

                    <div
                        class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
                        id="mobile-menu-2"
                    >
                        <ul
                            class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0"
                        >
                            <li>
                                <a
                                    href="/search-praticians"
                                    class="block py-2 pr-4 pl-3 text-primary border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                                    >Rendez-vous</a
                                >
                            </li>
                            <li>
                                <a
                                    href="/our-mission"
                                    class="block py-2 pr-4 pl-3 text-primary hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                                    >Notre mission</a
                                >
                            </li>
                            <li>
                                <a
                                    href="#services"
                                    class="block py-2 pr-4 pl-3 text-primary hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                                    >Services</a
                                >
                            </li>
                            <li>
                                <a
                                    href="/create-my-account"
                                    class="block py-2 pr-4 pl-3 text-primary rounded lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white"
                                    aria-current="page"
                                    >Inscription</a
                                >
                            </li>
                            <li>
                                <a
                                    :href="blogUrl"
                                    class="block py-2 pr-4 pl-3 text-primary border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                                    >Blog</a
                                >
                            </li>
                            <li>
                                <a
                                    href="/contact"
                                    class="block py-2 pr-4 pl-3 text-primary hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                                    >Contact</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    </div>
</template>

<script>
import { computed, onMounted, ref, onBeforeMount } from "vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { useStore } from "vuex";
import Button from "primevue/button";
import Avatar from "primevue/avatar";
import Menubar from "primevue/menubar";
import Badge from "primevue/badge";

export default {
    components: {
        Avatar,
        Button,
        Menu,
        Menubar,
        Badge,
        MenuButton,
        MenuItems,
        MenuItem,
    },
    setup() {
        const store = useStore();
        const items = computed(() => [
            {
                separator: true,
            },
            {
                label: "Mon espace",
                icon: "la la-tachometer-alt",
                url: "/account",
            },
            {
                label: "Profile",
                icon: "la la-user ",
                url: "/account/profile",
            },

            {
                separator: true,
            },
        ]);

        const sticking = ref(false);

        const blogUrl = computed(() =>
            typeof window != "undefined" ? `/blog` : ""
        );

        const user = computed(() => store.getters["auth/user"]);

        onBeforeMount(() => {
            if (typeof window != "undefined")
                window.addEventListener("scroll", handleScroll);
        });

        onMounted(() => {
            store.dispatch("auth/getUser", {});
        });

        const handleScroll = () => {
            if (typeof window != "undefined")
                if (window.pageYOffset) {
                    sticking.value = true;
                } else {
                    sticking.value = false;
                }
        };

        return { items, blogUrl, user, sticking };
    },
};
</script>
