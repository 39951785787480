<template></template>

<script>
export default {
    name: "AgoraChat",
    components: {},
    props: {
        authUser: { type: Object },
        allUsers: { type: Array },
        agoraId: { type: String },
        name: { type: String },
    },
    setup(props) {
        return {};
    },
};
</script>

<style scoped>
main {
    margin-top: 50px;
}
#video-container {
    width: 100%;
    height: 500px;
    max-width: 90vw;
    max-height: 50vh;
    margin: 0 auto;
    position: relative;
    background-color: #fff;
}
#local-video {
    width: 30%;
    height: 120px;
    border: 1px solid #fff;
    border-radius: 6px;
    z-index: 2;
    cursor: pointer;
}
#remote-video {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.action-btns {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -50px;
    z-index: 3;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
#login-form {
    margin-top: 100px;
}
</style>
