<template>
    <div class="video-stream h-full w-full">
        <video ref="videoElement" class="w-full h-full" />
        <audio ref="audioElement" />
    </div>
</template>
<script>
import { ref, onMounted, onUnmounted } from "vue";
import { defineComponent } from "vue";

export default defineComponent({
    props: { participant: Object, call: Object },
    setup(props) {
        const videoElement = ref(null);
        const audioElement = ref(null);
        const unbindVideoElement = ref();
        const unbindAudioElement = ref();

        onMounted(() => {
            if (videoElement.value) {
                // console.log(videoElement.value, audioElement.value, props.call);

                unbindVideoElement.value = props.call?.bindVideoElement(
                    videoElement.value,
                    props.participant?.sessionId || "sessionId",
                    "videoTrack"
                );

                console.log(unbindVideoElement.value);
            }
            if (audioElement.value) {
                unbindAudioElement.value = props.call?.bindAudioElement(
                    audioElement.value,
                    props.participant?.sessionId || "sessionId"
                );
            }
        });

        onUnmounted(() => {
            unbindVideoElement.value?.();
            unbindAudioElement.value?.();
        });

        return { videoElement, audioElement, unbindVideoElement };
    },
});
</script>

<style lang="scss">
.video-stream {
    video {
        object-fit: cover;
    }
}
</style>
