import Layout1 from "@/layouts/Layout1.vue";
import Cities from "@/views/settings/cities.vue";
import App from "@/views/index.vue";
import Staff from "@/views/settings/staff/index.vue";
import Cars from "@/views/settings/car/index.vue";
import ResourceType from "@/views/settings/resource-type/index";
import RecordType from "@/views/settings/record-type/index";
import StaffCreate from "@/views/settings/staff/create";
import StaffEdit from "@/views/settings/staff/edit";
import StaffDetails from "@/views/settings/staff/detail.vue";
import Resource from "@/views/resource/index";
import Patients from "@/views/patient/index";
import Dashboard from "@/views/dashboard/index";
import PatientDetail from "@/views/patient/detail";
import PatientEdit from "@/views/patient/edit";
import Alerts from "@/views/alert/index";
import AlertCreate from "@/views/alert/new";
import AlertDetail from "@/views/alert/details";
import PatientCreate from "@/views/patient/create";
import Hospitals from "@/views/hospital/index";
import HospitalDetails from "@/views/hospital/details";
import Prescriptions from "@/views/prescriptions/index";
import HospitalCreate from "@/views/hospital/create";
import HospitalEdit from "@/views/hospital/edit";
import Unauthorized from "@/views/auth/403";
import Account from "@/views/auth/account";
import PatientAccount from "@/views/Account/index";
import AccountDocuments from "@/views/Account/documents";
import AccountAppointments from "@/views/Account/appoints";
import AccountAppointment from "@/views/Account/appointmentDetail";
import AccountProfile from "@/views/auth/profile";
import AccountProfile2 from "@/views/Account/profile";
import AccountPortfolio from "@/views/auth/portfolio";
import AccountPortfolioDetails from "@/views/auth/portfolio-details";
import AccountPassword from "@/views/auth/password";
import AccountNotification from "@/views/auth/notification";
import Plannings from "@/views/planning/index";
import Appointments from "@/views/appointment/appointments";
import Invoices from "@/views/invoice/index";
import AppointmentDetails from "@/views/appointment/appointment";
import PlanningCreate from "@/views/planning/create";
import EditPlanning from "@/views/planning/create";
import EditorConfig from "@/views/blog/index";
import BlogWriters from "@/views/blog/users";
import NewBlogWriter from "@/views/blog/newUser";
import NewBlogPost from "@/views/blog/post";
import BlogPosts from "@/views/blog/posts";
import storage from "../services/Storage";
import { storageKeys } from "../services/endpoint";

const list = [

    {
        path: "/account",
        name: "patient.account",
        redirect: "/account/appointments",
        component: PatientAccount,
        meta: {
            requiresAuth: true,
            isAdmin: true,
            isHeading: false,
            name: "",
            keyName: "Tableau de bord",
            icon: "ri-hospital-fill",
        },
        children: [
            {
                name: "patient.appointments",
                component: AccountAppointments,
                meta: {
                    title: "Mes rendez-vous",
                    auth: false,
                    titleKey: "company.forbidden",
                },
                path: "appointments",
            },
            {
                name: "patient.appointment",
                component: AccountAppointment,
                meta: {
                    title: "Mon rendez-vous",
                    auth: false,
                    titleKey: "company.forbidden",
                },
                path: "appointments/:appointmentId",
            },
            {
                name: "patient.documents",
                component: AccountDocuments,
                meta: {
                    title: "Mes Documents",
                    auth: false,
                    titleKey: "company.forbidden",
                },
                path: "documents",
            },
            {
                name: "patient.profile",
                component: AccountProfile2,
                meta: {
                    title: "Profile utilisateur ",
                    auth: false,
                    titleKey: "company.forbidden",
                },
                path: "profile",
            },
        ],
    },

    {
        path: "/app",
        name: "dashboard",
        redirect: "",
        component: App,
        meta: {
            requiresAuth: true,
            isAdmin: true,
            isHeading: false,
            name: "",
            keyName: "Tableau de bord",
            icon: "ri-hospital-fill",
        },
        children: [
            {
                name: "auth.unauthorizedPage",
                component: Unauthorized,
                meta: { auth: false, titleKey: "company.forbidden" },
                path: "unauthorized",
            },
            {
                path: "",
                name: "app.dashboard",
                meta: {
                    title: "Tableau de board",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    auth: true,
                    name: "Tableau de bord",
                    keyName: "Cities",
                    icon: "las la-blind",
                    isHeading: false,
                    display: true,
                },
                component: Dashboard,
            },
            {
                path: "patients",
                name: "app.patients",
                meta: {
                    title: "Les patients ",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Patients",
                    keyName: "Cities",
                    icon: "las la-blind",
                    isHeading: false,
                    display: true,
                },
                component: Patients,
            },
            {
                path: "patients/:id/details",
                name: "patient.details",
                meta: {
                    title: "Détails du patient",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Détails du patient",
                    keyName: "patientDetails",
                    icon: "las la-blind",
                    isHeading: false,
                    display: false,
                },
                component: PatientDetail,
            },
            {
                path: "patients/:patientId/edit",
                name: "patient.edit",
                meta: {
                    title: "Edition profil du patient",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Edition profil du patient",
                    keyName: "patientDetails",
                    icon: "las la-blind",
                    isHeading: false,
                    display: false,
                },
                component: PatientEdit,
            },
            {
                path: "alertes",
                name: "app.alerts",
                meta: {
                    title: "Les alertes",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Alertes",
                    keyName: "Cities",
                    icon: "las la-bell",
                    isHeading: false,
                    display: true,
                },
                component: Alerts,
            },
            {
                path: "alertes/lancer",
                name: "app.alertCreate",
                meta: {
                    title: "Création d'alerte",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: { name: "unauthorizedPage", replace: true },
                    requiresAuth: true,
                    name: "Nouvelle alerte",
                    keyName: "Nouvelle alerte",
                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                },
                component: AlertCreate,
            },
            {
                path: "alertes/:id",
                name: "app.alerts_get",
                meta: {
                    title: "Détails alerte",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Détails alerte",
                    keyName: "Cities",
                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                    linkedTo: "sidebar.alerts",
                },
                component: AlertDetail,
            },
            {
                path: "plannings",
                name: "app.plannings",
                meta: {
                    title: "Détails alerte",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Détails alerte",
                    keyName: "Cities",
                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                    linkedTo: "sidebar.alerts",
                },
                component: Plannings,
            },
            {
                path: "appointments",
                name: "app.appointments",
                meta: {
                    title: "Les Rendez-vous",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Les Rendez-vous",
                    keyName: "Cities",
                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                    linkedTo: "sidebar.alerts",
                },
                component: Appointments,
            },

            {
                path: "appointments/:appointmentId",
                name: "app.appointment",
                meta: {
                    title: "Les Rendez-vous",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Les Rendez-vous",
                    keyName: "Cities",
                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                    linkedTo: "sidebar.alerts",
                },
                component: AppointmentDetails,
            },

            {
                path: "invoices",
                name: "app.invoices",
                meta: {
                    title: "Facturation",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Facturation",

                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                    linkedTo: "sidebar.alerts",
                },
                component: Invoices,
            },
            {
                path: "plannings/create",
                name: "app.newPlanning",
                meta: {
                    title: "Détails alerte",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Détails alerte",
                    keyName: "Cities",
                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                    linkedTo: "sidebar.alerts",
                },
                component: PlanningCreate,
            },
            {
                path: "plannings/:planningId/edit",
                name: "app.editPlanning",
                meta: {
                    title: "Détails alerte",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Détails alerte",
                    keyName: "Cities",
                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                    linkedTo: "sidebar.alerts",
                },
                component: EditPlanning,
            },
            {
                path: "patients/create",
                name: "app.patientCreate",
                meta: {
                    title: "Ajouter un patient",
                    anyCan: ["is-admin", "doctor", "manager"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    name: "Nouveau patient",
                    requiresAuth: true,
                    keyName: "Cities",
                    icon: "las la-medkit",
                    isHeading: false,
                    display: false,
                    linkedTo: "sidebar.patients",
                },
                component: PatientCreate,
            },
            {
                path: "resources",
                name: "config.resources",
                meta: {
                    title: "Les ressources pour ambulances",
                    anyCan: ["is-admin", "manager"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: false,
                    },
                    requiresAuth: true,
                    name: "Ressources",
                    keyName: "Cities",
                    icon: "las la-medkit",
                    isHeading: false,
                    display: true,
                },
                component: Resource,
            },
            {
                path: "cities",
                name: "config.cities",
                route: "config.cities",
                meta: {
                    title: "Les Villes",

                    anyCan: ["is-admin"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Villes",
                    keyName: "Tableau de bord",
                    icon: "las la-city",
                    display: false,
                    parent: "Configuration",
                },
                component: Cities,
            },
            {
                path: "staffs",
                name: "config.staff",
                meta: {
                    title: "Les membres d'équipe",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Équipe médicale",
                    keyName: "Tableau de bord",
                    icon: "las la-user-nurse",
                    display: false,
                    parent: "Configuration",
                },
                component: Staff,
            },
            {
                path: "staffs/create",
                name: "config.staffCreate",
                meta: {
                    title: "Ajouter un membre d'équipe",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Nouveau membre",
                    keyName: "Tableau de bord",
                    icon: "las la-user-nurse",
                    display: false,
                    parent: "Configuration",
                },
                component: StaffCreate,
            },
            {
                path: "staffs/:identifier/edit",
                name: "config.staffEdit",
                meta: {
                    title: "Éditer un membre d'équipe",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Édition équipe",
                    keyName: "Tableau de bord",
                    icon: "las la-user-nurse",
                    display: false,
                    parent: "Configuration",
                },
                component: StaffEdit,
            },
            {
                path: "staffs/:identifier/details",
                name: "config.staffDetails",
                meta: {
                    title: "Éditer un membre d'équipe",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Édition équipe",
                    keyName: "Tableau de bord",
                    icon: "las la-user-nurse",
                    display: false,
                    parent: "Configuration",
                },
                component: StaffDetails,
            },
            {
                path: "blog/configs",
                name: "blog.config",
                meta: {
                    title: "Éditeur",
                    anyCan: ["is-admin", "editor"],
                    requiresAuth: true,
                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                    linkedTo: "sidebar.alerts",
                },
                component: EditorConfig,
            },
            {
                path: "blog/writers",
                name: "blog.writers",
                meta: {
                    title: "Éditeurs",
                    anyCan: ["is-admin"],
                    requiresAuth: true,
                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                },
                component: BlogWriters,
            },
            {
                path: "blog/writers/new",
                name: "blog.newWriter",
                meta: {
                    title: "Nouvel Éditeur",
                    anyCan: ["is-admin"],
                    requiresAuth: true,
                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                },
                component: NewBlogWriter,
            },

            {
                path: "blog/posts/new",
                name: "blog.newPost",
                meta: {
                    title: "Nouvel articles",
                    anyCan: ["is-admin"],
                    requiresAuth: true,
                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                },
                component: NewBlogPost,
            },
            {
                path: "blog/posts",
                name: "blog.posts",
                meta: {
                    title: "Articles - Blog",
                    anyCan: ["is-admin"],
                    requiresAuth: true,
                    icon: "las la-bell",
                    isHeading: false,
                    display: false,
                },
                component: BlogPosts,
            },
            {
                path: "ambulances",
                name: "config.cars",
                meta: {
                    title: "Les ambulances du comptes",
                    anyCan: ["is-admin", "manager"],
                    onDeniedRoute: { name: "unauthorizedPage", replace: true },
                    requiresAuth: true,
                    name: "Ambulance",
                    keyName: "Tableau de bord",
                    icon: "las la-ambulance",
                    display: false,
                    parent: "Configuration",
                },
                component: Cars,
            },
            {
                path: "resource-types",
                name: "config.resource-types",
                meta: {
                    title: "Les types de ressources disponibles",
                    anyCan: ["is-admin", "manager"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Types de ressources",
                    keyName: "Tableau de bord",
                    icon: "las la-stethoscope",
                    display: false,
                    parent: "Configuration",
                },
                component: ResourceType,
            },
            {
                path: "record-types",
                name: "config.record-types",
                meta: {
                    title: "Les types de données enrégistrées",
                    anyCan: ["is-admin"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Types d'enrégistrement",
                    keyName: "Tableau de bord",
                    icon: "las la-medkit",
                    display: false,
                    parent: "Configuration",
                },
                component: RecordType,
            },
            {
                path: "config",
                name: "Configuration",
                component: Layout1,
                meta: {
                    title: "Configuration",
                    requiresAuth: true,
                    name: "Configuration",
                    keyName: "Cities",
                    icon: "ri-hospital-fill",
                    display: true,
                    isHeading: false,
                },
            },
            {
                path: "hospitals",
                name: "config.hospitals",
                meta: {
                    title: "Les hôpitaux du compte",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Hôpitaux",
                    keyName: "Hospitals",
                    icon: "las la-clinic-medical",
                    isHeading: false,
                    display: true,
                },
                component: Hospitals,
            },
            {
                path: "hospitals/:slug",
                name: "config.hospitalDetails",
                meta: {
                    title: "Détails hôpital",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Détails hôpital",
                    keyName: "Hospitals",
                    icon: "las la-clinic-medical",
                    isHeading: false,
                    display: false,
                },
                component: HospitalDetails,
            },
            {
                path: "hospitals/:slug/modifier",
                name: "config.editMedicalCenter",
                meta: {
                    title: "Modifier un hôpital",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Édition hôpital",
                    keyName: "Hospitals",
                    icon: "las la-clinic-medical",
                    isHeading: false,
                    display: false,
                },
                component: HospitalEdit,
            },
            {
                path: "hospitals/:slug/staffs/create",
                name: "medical.staffCreate",
                meta: {
                    title: "Créer un staff d'un hôpital",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Staff",
                    keyName: "Tableau de bord",
                    icon: "las la-user-nurse",
                    display: false,
                    parent: "Configuration",
                },
                component: StaffCreate,
            },
            {
                path: "hospitals/:slug/patients/:id/details",
                name: "medical.patientDetails",
                meta: {
                    title: "Détails profil patient",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Détails patient",
                    keyName: "Tableau de bord",
                    icon: "las la-user-nurse",
                    display: false,
                    parent: "Configuration",
                },
                component: PatientDetail,
            },
            {
                path: "hospitals/:slug/patients/create",
                name: "medical.patientCreate",
                meta: {
                    title: "Ajout d'un nouveau patient",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Nouveau patient",
                    keyName: "Cities",
                    icon: "las la-medkit",
                    isHeading: false,
                    display: false,
                    linkedTo: "sidebar.patients",
                },
                component: PatientCreate,
            },
            {
                path: "prescriptions",
                name: "sidebar.prescriptions",
                meta: {
                    title: "Les prescriptions",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Prescriptions",
                    keyName: "Hospitals",
                    icon: "las la-diagnoses",
                    isHeading: false,
                    display: true,
                },
                component: Prescriptions,
            },
            {
                path: "hospitals/create",
                name: "config.hospitalsCreate",
                meta: {
                    title: "Création d'hôpital",
                    anyCan: ["is-admin", "manager", "doctor"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    name: "Nouvel hôpital",
                    keyName: "Hospitals",
                    icon: "las la-clinic-medical",
                    isHeading: false,
                    display: false,
                    linkedTo: "sidebar.hospitals",
                },
                component: HospitalCreate,
            },
            {
                path: "account/portfolios",
                name: "account.portfolio",
                meta: {
                    anyCan: ["is-user"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: false,
                },
                component: AccountPortfolio,
            },
            {
                path: "account/portfolios/:portfolioId",
                name: "account.portfolioDetail",
                meta: {
                    anyCan: ["is-user"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: false,
                },
                component: AccountPortfolioDetails,
            },

            {
                path: "/app/account/profile",
                name: "account",
                component: Account,
                meta: {
                    title: "Profile utilisateur",
                    anyCan: ["is-user"],
                    onDeniedRoute: {
                        name: "auth.unauthorizedPage",
                        replace: true,
                    },
                    requiresAuth: true,
                    isAdmin: true,
                    isHeading: false,
                    name: "",
                    keyName: "Tableau de bord",
                    icon: "ri-hospital-fill",
                },
                children: [
                    {
                        path: "",
                        name: "account.profile",
                        meta: {
                            title: "Profile utilisateur",
                            anyCan: ["is-user"],
                            onDeniedRoute: {
                                name: "auth.unauthorizedPage",
                                replace: true,
                            },
                            requiresAuth: false,
                        },
                        component: AccountProfile,
                    },

                    {
                        path: "password",
                        name: "account.password",
                        meta: {
                            title: "Mot de passe",
                            anyCan: ["is-user"],
                            onDeniedRoute: {
                                name: "auth.unauthorizedPage",
                                replace: true,
                            },
                            requiresAuth: false,
                        },
                        component: AccountPassword,
                    },
                    {
                        path: "notification",
                        name: "account.notification",
                        meta: {
                            title: "Notifications",
                            anyCan: ["is-user"],
                            onDeniedRoute: {
                                name: "auth.unauthorizedPage",
                                replace: true,
                            },
                            requiresAuth: false,
                        },
                        component: AccountNotification,
                    },
                ],
            },
        ],
    },
];

export const routes = list;

export const generatedRoutes = [];
