<template>
    <div class="bg-[#F6F8FE] flex flex-col items-center py-14">
        <div class="w-10/12">
            <div
                class="flex xs:flex-col sm:flex-col items-center justify-between xs:mb-5 sm:mb-5"
            >
                <div class="w-9/12 xs:w-full sm:w-full">
                    <div class="flex">
                        <img src="/images/line.svg" class="w-4" alt="" />
                        <span
                            class="px-1 text-xs font-light uppercase text-[#A280FF]"
                            >Actualité santé</span
                        >
                        <img src="/images/line.svg" class="w-4" alt="" />
                    </div>

                    <div class="w-full">
                        <h1
                            class="py-2 xs:text-xl text-black text-6xl py-5 font-extrabold font-ibm"
                        >
                            Nos articles sur la santé et le bien-être personnel
                        </h1>
                    </div>
                </div>

                <div
                    class="w-1/5 xs:w-full sm:w-full flex justify-end xs:justify-start sm:justify-start"
                >
                    <a
                        :href="blogUrl"
                        target="_blank"
                        class="bg-primary3 font-normal text-white rounded-lg border-none outline-none px-3 py-2"
                    >
                        Lire notre Blog
                    </a>
                </div>
            </div>
        </div>

        <div class="flex w-10/12">
            <div
                class="xl:grid xl:grid-cols-3 xs:grid-row-3 xs:grid-flow-col gap-4 w-full"
            >
                <a
                    v-for="(post, index) in posts"
                    :key="index"
                    :href="getUrl(post.slug)"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div class="w-full cursor-pointer">
                        <div class="rounded-lg overflow-hidden h-[300px]">
                            <img
                                :src="post.thumbnail_url"
                                alt=""
                                class="w-full object-cover h-full"
                            />
                        </div>

                        <div
                            class="flex text-slate-500 my-4 font-light text-xs"
                        >
                            <span>{{
                                moment(post.published_at).format("DD MMM YYYY")
                            }}</span>
                            <span class="mx-2">.</span>
                            <span
                                >{{ calculateReadingTime(post) }} min de
                                lecture</span
                            >
                        </div>
                        <h1 class="py-2 text-lg font-normal font-inter">
                            {{ post.title }}
                        </h1>

                        <div class="flex items-center mt-4 mb-5">
                            <div
                                class="flex w-8 h-8 rounded-full overflow-hidden"
                            >
                                <img
                                    src="/images/dct-img.avif"
                                    class="object-fill h-10 w-10"
                                    alt=""
                                />
                            </div>

                            <p class="pl-2 font-light text-xs text-slate-700">
                                Par {{ post.author?.full_name ?? "-" }}
                            </p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { computed, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
export default {
    setup() {
        const store = useStore();
        const posts = ref([]);

        const blogUrl = computed(() =>
            typeof window != "undefined" ? `/blog` : ""
        );

        const load = async () => {
            try {
                store.dispatch("blog/postsSearch", {
                    filter: {},
                    onSuccess: ({ data, current_page, last_page }) => {
                        posts.value = [...data];
                    },
                    onError: () => {},
                });
            } catch (error) {}
        };

        const calculateReadingTime = (post) => {
            const value = Math.floor(post.content.length / 1100);
            return value ? value : 2;
        };

        const getUrl = (postUrl) => {
            return `${blogUrl.value}/en-bonne-sante/${postUrl}`;
        };

        onBeforeMount(() => {
            load();
        });

        return { posts, blogUrl, moment, calculateReadingTime, getUrl };
    },
};
</script>
