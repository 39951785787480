<template>
    <div class="w-full flex justify-between" v-if="user">
        <div class="w-1/4">
            <div class="bg-white rounded-lg p-3" style="width: 95%">
                <div class="flex flex-col items-center w-full">
                    <div
                        v-if="user"
                        class="w-2/5 flex flex-col items-center justify-center bg-primary rounded-xl overflow-hidden p-1 bg-opacity-15"
                    >
                        <img
                            class="bottom-0"
                            :src="user?.avatar"
                            alt="Bonnie image"
                        />
                    </div>

                    <div class="mt-3">
                        <h3 class="font-bold text-lg">{{ user.full_name }}</h3>
                        <p class="py-1">{{ user.username }}</p>
                    </div>
                </div>

                <div
                    class="border-t border-slate-100 mt-3 pt-2"
                    v-if="user?.profile?.description"
                >
                    <h3 class="font-bold">À propos</h3>

                    <p class="text-justify">
                        {{ user.profile.description }}
                    </p>
                </div>

                <div class="border-t border-slate-100 mt-3 pt-2">
                    <h3 class="font-bold">Postes</h3>

                    <div>
                        <div
                            class="bg-slate-100 rounded p-2 mb-2"
                            v-for="account in user?.accounts"
                            :key="account.id"
                        >
                            <p>
                                {{ account.medical_center.name }}
                                <span
                                    v-if="account.role"
                                    class="text-xs font-medium"
                                >
                                    ({{ account.role.name }})</span
                                >
                            </p>
                            <p>
                                <span class="text-xs"
                                    >{{
                                        account.medical_center.specialties
                                            .map((x) => x.name)
                                            .join(", ")
                                    }}
                                </span>
                            </p>

                            <p>
                                <span class="text-xs"
                                    >Date de début:
                                    {{
                                        moment(account.from).format("ll")
                                    }}</span
                                >
                            </p>
                            <p v-if="account.to">
                                <span class="text-xs"
                                    >Date de fin:
                                    {{ moment(account.to).format("ll") }}</span
                                >
                            </p>
                        </div>
                    </div>
                </div>

                <div class="border-t border-slate-100 mt-3 pt-2">
                    <h3 class="font-bold">Contact</h3>

                    <div>
                        <div class="w-full flex">
                            <p class="w-100">Téléphone:</p>
                            <div class="ml-2">
                                <p>{{ user.phone_1 }}</p>
                                <p v-if="user.phone_2">{{ user.phone_2 }}</p>
                            </div>
                        </div>

                        <div class="w-full flex mt-1">
                            <p class="w-100">E-mail:</p>
                            <div class="ml-2">
                                <p>{{ user.email }}</p>
                            </div>
                        </div>

                        <div class="w-full flex mt-1">
                            <p class="w-100">Adresse:</p>
                            <div class="ml-2">
                                <p>{{ user.address }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-3/4 flex flex-col">
            <div class="flex justify-between">
                <div class="flex" style="width: 70%">
                    <div class="w-1/3 flex justify-start">
                        <div style="width: 95%">
                            <div
                                class="rounded-lg bg-success bg-opacity-15 p-2 flex flex-col"
                            >
                                <div class="flex">
                                    <div
                                        class="w-6 h-6 flex justify-center items-center rounded bg-white text-center mr-2"
                                    >
                                        <i class="la la-users text-lg"></i>
                                    </div>

                                    <p class="text-sm font-normal">
                                        Nombre de patients
                                    </p>
                                </div>
                                <div
                                    class="flex justify-between items-end pt-5"
                                >
                                    <span
                                        ><h3 class="font-semibold text-4xl">
                                            {{ statisticsResponse?.patients }}
                                        </h3></span
                                    >
                                    <div class="px-2 py-1">
                                        <div
                                            class="flex bg-white rounded-xl px-2"
                                        >
                                            <i
                                                class="la la-arrow-up text-xs"
                                            ></i>
                                            <p class="text-xs">25%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-1/3 flex justify-start">
                        <div style="width: 95%">
                            <div
                                class="rounded-lg bg-danger bg-opacity-15 p-2 flex flex-col"
                            >
                                <div class="flex">
                                    <div
                                        class="w-6 h-6 flex justify-center items-center rounded bg-white text-center mr-2"
                                    >
                                        <i
                                            class="la la-calendar-alt text-lg"
                                        ></i>
                                    </div>

                                    <p class="text-sm font-normal">
                                        Nombre de consultations
                                    </p>
                                </div>
                                <div
                                    class="flex justify-between items-end pt-5"
                                >
                                    <span
                                        ><h3 class="font-semibold text-4xl">
                                            {{
                                                statisticsResponse?.appointments
                                            }}
                                        </h3></span
                                    >
                                    <div class="px-2 py-1">
                                        <div
                                            class="flex bg-white rounded-xl px-2"
                                        >
                                            <i
                                                class="la la-arrow-up text-xs"
                                            ></i>
                                            <p class="text-xs">25%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-1/3 flex justify-start">
                        <div style="width: 95%">
                            <div
                                class="rounded-lg bg-warning bg-opacity-15 p-2 flex flex-col"
                            >
                                <div class="flex">
                                    <div
                                        class="w-6 h-6 flex justify-center items-center rounded bg-white text-center mr-2"
                                    >
                                        <i class="la la-medal text-lg"></i>
                                    </div>

                                    <p class="text-sm font-normal">
                                        Note globale
                                    </p>
                                </div>
                                <div
                                    class="flex justify-between items-end pt-5"
                                >
                                    <span
                                        ><h3 class="font-semibold text-4xl">
                                            {{
                                                statisticsResponse.evaluations ??
                                                0
                                            }}
                                        </h3></span
                                    >
                                    <div class="px-2 py-1">
                                        <div
                                            class="flex bg-white rounded-xl px-2"
                                        >
                                            <i
                                                class="la la-arrow-up text-xs"
                                            ></i>
                                            <p class="text-xs">25%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="" style="width: 30%">
                    <div class="bg-white rounded-lg p-3" style="width: 100%">
                        <h3 class="text-slate-700 font-bold">
                            Rendez-vous à venir
                        </h3>

                        <div class="mt-4">
                            <div
                                :class="`flex flex-col mb-5 ${
                                    index > 0
                                        ? 'border-t border-slate-100 pt-4'
                                        : ''
                                }`"
                                v-for="(appointment, index) in nextAppointments"
                                :key="index"
                            >
                                <div class="flex justify-between">
                                    <span
                                        class="flex items-center px-2 text-xs bg-success2 rounded-lg font-bold"
                                    >
                                        <div
                                            class="w-2 h-2 rounded-full bg-success mr-1"
                                        ></div>
                                        {{
                                            moment(appointment.start_date)
                                                .locale("fr")
                                                .format("dddd, DD MMM yyyy")
                                        }}
                                    </span>

                                    <p class="text-xs text-slate-800 font-bold">
                                        <i class="la la-clock text-xs"></i>
                                        {{
                                            moment(
                                                appointment.start_date
                                            ).format("hh:mm A")
                                        }}
                                    </p>
                                </div>

                                <div class="mt-2 flex">
                                    <div
                                        class="w-10 h-10 rounded-full overflow-hidden bg-slate-200"
                                    >
                                        <img
                                            :src="appointment.patient.avatar"
                                            alt=""
                                            class="object-cover"
                                        />
                                    </div>
                                    <div class="ml-2">
                                        <h3
                                            class="text-sm font-semibold text-slate-900"
                                        >
                                            {{ appointment.patient.full_name }}
                                        </h3>
                                        <p class="text-xs text-slate-500">
                                            {{ appointment.specialty.name }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-2">
                <div class="p-2 rounded bg-white">
                    <h3 class="px-2 font-semibold py-2">Les patients</h3>

                    <div>
                        <div v-if="patients.length > 0" class="w-full">
                            <table
                                striped
                                outlined
                                class="table table-striped"
                                hover
                            >
                                <thead>
                                    <tr>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Identifiant
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Nom
                                        </th>

                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Ville
                                        </th>

                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Naissance
                                        </th>
                                        <th
                                            class="whitespace-nowrap font-medium text-sm"
                                        >
                                            Création
                                        </th>

                                        <th class="whitespace-nowrap"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="user in patients" :key="user.id">
                                        <td class="text-sm text-slate-700">
                                            <span
                                                class="text-blackp text-xs font-semibold mr-2 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900"
                                                >{{ user.username }}</span
                                            >
                                        </td>

                                        <td class="text-sm text-slate-700">
                                            <span>{{
                                                user.gender === "m"
                                                    ? "Mr."
                                                    : "Mme."
                                            }}</span>
                                            {{ user.full_name }}
                                        </td>

                                        <td class="text-sm text-slate-700">
                                            {{ user.city?.name }}
                                        </td>

                                        <td class="text-sm text-slate-700">
                                            {{
                                                user.birth_date
                                                    ? moment(
                                                          user.birth_date
                                                      ).format("L")
                                                    : "-"
                                            }}
                                        </td>
                                        <td class="text-sm text-slate-700">
                                            {{
                                                moment(user.createdAt).format(
                                                    "L"
                                                )
                                            }}
                                        </td>

                                        <td>
                                            <div class="flex justify-end">
                                                <button
                                                    class="bg-slate-400 text-sm bg-opacity-25 mr-1 px-2 py-1 rounded text-slate-400"
                                                    size="sm"
                                                    @click="
                                                        gotoDetails(user.id)
                                                    "
                                                >
                                                    <i
                                                        class="las la-eye m-0"
                                                    ></i>
                                                </button>
                                                <button
                                                    class="bg-secondary text-sm bg-opacity-25 mr-1 px-2 py-1 rounded text-secondary"
                                                    size="sm"
                                                    @click="
                                                        $router.push({
                                                            name: 'patient.edit',
                                                            params: {
                                                                patientId:
                                                                    user.username
                                                                        ?.toString()
                                                                        ?.toLowerCase(),
                                                            },
                                                        })
                                                    "
                                                >
                                                    <i class="las la-pen"></i>
                                                </button>
                                                <button
                                                    class="px-2 text-sm py-1 bg-danger rounded text-danger bg-opacity-25"
                                                    size="sm"
                                                    @click="handlePopup(user)"
                                                >
                                                    <i
                                                        class="la la-trash m-0"
                                                    ></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div v-else class="p-3 h-full">
                            <div
                                class="flex flex-col justify-center items-center h-full"
                            >
                                <img
                                    class="w-1/4"
                                    src="/images/no-result.svg"
                                />
                                <h4
                                    class="text-primary font-medium my-3 text-md"
                                >
                                    Aucun patient enrégistré pour le moment
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-5">
                    <h3 class="px-2 font-semibold py-2">
                        Evaluations des patients
                    </h3>

                    <div class="w-full flex justify-start">
                        <div
                            class="bg-white rounded-xl p-3 mr-5"
                            style="width: 30%"
                            v-for="(
                                evaluation, index
                            ) in evaluationsResponse.data"
                            :key="index"
                        >
                            <div
                                class="flex justify-between items-center border-b border-slate-100 mb-2 pb-2"
                            >
                                <div class="flex">
                                    <div
                                        class="w-5 h-5 rounded-full overflow-hidden bg-slate-200"
                                    >
                                        <img
                                            :src="evaluation.patient.avatar"
                                            alt=""
                                            class="object-cover"
                                        />
                                    </div>
                                    <div class="ml-2">
                                        <h3
                                            class="text-sm font-medium text-slate-800"
                                        >
                                            {{ evaluation.patient.full_name }}
                                        </h3>
                                    </div>
                                </div>

                                <vue3-star-ratings
                                    v-model="evaluation.motive_value"
                                    star-size="10"
                                    disableClick
                                />
                            </div>
                            <div>
                                <p class="text-slate-500">
                                    {{ evaluation.comment }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import vue3StarRatings from "vue3-star-ratings";
moment.locale("fr");
export default defineComponent({
    components: { vue3StarRatings },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const user = ref(null);
        const nextAppointments = ref([]);
        const patients = ref([]);
        const evaluationsResponse = ref({ data: [] });
        const statisticsResponse = ref({});
        const ratingValue = ref(3);
        const getNextAppointments = () => {
            store.dispatch("medical/listNextAppointments", {
                filter: {},
                onSuccess: (data) => {
                    nextAppointments.value = [...data];
                },
                onError: () => {},
            });
        };

        const getData = (page = 1, userId) => {
            store.dispatch("medical/getDoctorPatients", {
                page,
                // portfolioId: portfolio.value.id,
                doctorId: userId,
                onSuccess: (response) => {
                    patients.value = [...response.data];
                },
                onError: () => {},
            });
        };

        const getEvaluations = (page = 1) => {
            store.dispatch("medical/getDoctorEvaluations", {
                page,
                perPage: 3,
                // medicalCenterId:
                // portfolioId: portfolio.value.id,
                // doctorId: userId,
                onSuccess: (response) => {
                    evaluationsResponse.value = {
                        ...response,
                    };
                },
                onError: () => {},
            });
        };

        const getStatistics = () => {
            store.dispatch("medical/getDoctorStatistics", {
                onSuccess: (response) => {
                    console.log("getDoctorStatistics", response.evaluations);

                    statisticsResponse.value = {
                        ...response,
                        evaluations: parseFloat(response.evaluations).toFixed(
                            1
                        ),
                    };
                },
                onError: () => {},
            });
        };

        const gotoDetails = (id) => {
            router.push({ name: "patient.details", params: { id: id } });
        };

        const getUser = () => {
            store.dispatch("medical/getStaff", {
                identifier: route.params.identifier,
                onSuccess: (data) => {
                    console.log("data.id", data.id);

                    getData(1, data.id);
                    user.value = {
                        ...data,
                        last_name: data.last_name,
                        first_name: data.first_name,
                        address: data.address,
                        latest_medical_account: data.latest_medical_account,
                        // hospital: data.latest_medical_account
                        //     ? {
                        //           ...hospitals.value.find(
                        //               (x) =>
                        //                   x.id ==
                        //                   data?.latest_medical_account
                        //                       ?.medical_center_id
                        //           ),
                        //       }
                        //     : {},
                        doctor_from: data?.latest_medical_account?.from,
                        doctor_to: data?.latest_medical_account?.to,
                        email: data.email,
                        phone_1: `+${data.phone_1}`,
                        phone_2: data.phone_2 ? `+${data.phone_2}` : "",
                        city: data.city,
                        gender: {
                            id: data.gender?.toString()?.toLowerCase(),
                            // name: options.value.find(
                            //     (x) =>
                            //         x.id ==
                            //         data.gender?.toString()?.toLowerCase()
                            // )?.name,
                        },
                        roles: data.roles.filter((r) => r.level > 2),
                    };
                },
            });
        };

        onMounted(() => {
            getUser();
            getNextAppointments();
            getEvaluations(1);
            getStatistics();
        });

        return {
            user,
            ratingValue,
            statisticsResponse,
            evaluationsResponse,
            patients,
            nextAppointments,
            moment,
            gotoDetails,
        };
    },
});
</script>
