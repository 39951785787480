<template>
    <div class="w-full h-full transition-all" v-if="remoteParticipant">
        <section
            class="content-section rounded-lg overflow-hidden"
            v-if="showRemoteVideo"
        >
            <VideoComponent :call="call" :participant="remoteParticipant" />
        </section>
        <section
            class="content-section h-full rounded-lg overflow-hidden border-4 border-slate-300"
            v-else
        >
            <div
                class="w-full h-full flex bg-primary bg-opacity-50 justify-center items-center"
            >
                <div
                    class="h-[80px] w-[80px] rounded-full font-bold bg-primary text-white flex justify-center items-center"
                >
                    <h1 class="text-2xl">{{ initials }}</h1>
                </div>
            </div>
            <!-- <VideoComponent :call="call" :participant="localParticipant" /> -->
        </section>
    </div>
</template>
<script>
import { computed, ref } from "vue";
import VideoComponent from "./video.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: { VideoComponent },
    props: { call: Object, remoteParticipant: Object },
    setup(props) {
        const initials = computed(() =>
            (props.member?.name ?? "")
                ?.match(/(^\S\S?|\b\S)?/g)
                .join("")
                .match(/(^\S|\S$)?/g)
                .join("")
                .toUpperCase()
        );
        const showRemoteVideo = computed(() => {
            return props.call && props.remoteParticipant;
        });
        // async function leaveStream() {
        //     await props.call?.leave();
        //     props.remoteParticipantSub?.unsubscribe();

        //     // cpo = undefined
        // }
        return { showRemoteVideo, initials };
    },
});
</script>
