<template>
    <div class="w-full flex justify-center mb-10">
        <div class="w-9/12 mt-8">
            <div class="w-full flex flex-col px-3">
                <div class="w-full py-3">
                    <h3 class="font-roboto font-bold text-lg text-slate-500">
                        Informations
                    </h3>
                </div>

                <form @submit.prevent="submitForm">
                    <div class="">
                        <h4 class="py-2">Photo de profile</h4>
                        <div
                            :style="`background-image: url(${user?.avatar});background-size: cover;background-repeat: no-repeat;`"
                            :class="`relative w-20 h-20 rounded bg-doto-500 `"
                        >
                            <div
                                class="absolute w-5 h-5 flex justify-center items-center rounded bg-white bottom-0 right-0"
                            >
                                <i
                                    class="la la-pen text-slate-600 cursor-pointer"
                                    @click.prevent="loadFile"
                                ></i>
                            </div>
                        </div>
                    </div>

                    <div class="w-full mb-3">
                        <div class="flex mt-4 mb-2">
                            <div class="w-1/3 mr-1">
                                <label
                                    class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                                    for="grid-last-name"
                                    >Nom de famille</label
                                >
                                <div class="h-10">
                                    <Field
                                        v-slot="{ field }"
                                        name="last_name"
                                        v-model="user.last_name"
                                    >
                                        <InputText
                                            v-bind="field"
                                            name="last_name"
                                            class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                            id="grid-last-name"
                                            type="text"
                                            placeholder="Doe"
                                        />
                                    </Field>
                                </div>
                            </div>
                            <div class="w-1/3 ml-1 mr-1">
                                <label
                                    class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                                    for="grid-first-name"
                                    >Prénom(s)</label
                                >
                                <div class="h-10">
                                    <Field
                                        v-slot="{ field }"
                                        name="first_name"
                                        v-model="user.first_name"
                                    >
                                        <InputText
                                            name="first_name"
                                            v-model="user.first_name"
                                            v-bind="field"
                                            class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                            id="grid-first-name"
                                            type="text"
                                            placeholder="Jane"
                                        />
                                    </Field>
                                </div>
                            </div>
                            <div class="w-1/3 ml-1">
                                <label
                                    class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                                    for="grid-phone-1"
                                    >Email</label
                                >
                                <div class="h-10">
                                    <Field
                                        v-slot="{ field }"
                                        name="email"
                                        v-model="user.email"
                                    >
                                        <InputText
                                            v-bind="field"
                                            name="email"
                                            v-model="user.email"
                                            class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                            id="email"
                                            disabled
                                            type="text"
                                            placeholder="test@example.com"
                                        />
                                    </Field>
                                </div>
                            </div>
                        </div>
                        <div class="flex mt-3 mb-2">
                            <div class="w-1/3 mr-1">
                                <label
                                    class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                                    for="grid-email"
                                    >Surnom (optionnel)</label
                                >
                                <div class="h-10">
                                    <Field
                                        v-slot="{ field }"
                                        name="nickname"
                                        v-model="user.nickname"
                                    >
                                        <InputText
                                            v-bind="field"
                                            autocomplete="off"
                                            name="nickname"
                                            v-model="user.nickname"
                                            placeholder="Surnom"
                                            class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                            type="text"
                                        />
                                    </Field>
                                </div>
                            </div>
                            <div class="w-1/3 mr-1 ml-1">
                                <label
                                    class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                                    for="grid-email"
                                    >Téléphone 1</label
                                >
                                <div class="h-10">
                                    <div
                                        class="border border-slate-300 bg-white rounded h-10"
                                    >
                                        <Field
                                            v-slot="{ field }"
                                            name="phone_1"
                                            v-model="user.phone_1"
                                        >
                                            <phone-number-input
                                                type="text"
                                                v-bind="field"
                                                :defaultCountry="countryCode"
                                                placeholder="Numero de télephone *"
                                                class="doctor-info-phone w-full border border-slate-300 bg-white px-2 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:border focus:border-blue-200"
                                            />
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 ml-1">
                                <label
                                    class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                                    for="grid-email"
                                    >Téléphone 2</label
                                >
                                <div class="h-10">
                                    <div
                                        class="border border-slate-300 bg-white rounded h-10"
                                    >
                                        <Field
                                            v-slot="{ field }"
                                            name="phone_2"
                                            v-model="user.phone_2"
                                        >
                                            <phone-number-input
                                                type="text"
                                                v-bind="field"
                                                :defaultCountry="countryCode"
                                                placeholder="Numero de télephone *"
                                                class="doctor-info-phone w-full bg-white px-2 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:border focus:border-blue-200"
                                            />
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex mt-3 mb-2">
                            <div class="w-1/3 mr-1">
                                <label
                                    class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                                    for="grid-state"
                                    >Ville</label
                                >
                                <div class="h-10">
                                    <Field
                                        v-slot="{}"
                                        name="city"
                                        v-model="user.city"
                                    >
                                        <Dropdown
                                            v-model="user.city"
                                            :options="cities"
                                            optionLabel="name"
                                            placeholder="Ville"
                                            :inputStyle="{ paddingTop: '8px' }"
                                            class="w-full focus:shadow-none shadow-none bg-white rounded border border-slate-300 h-full w-full focus:border-slate-400 transition transition-all"
                                            @update:modelValue="handleCity"
                                        />
                                    </Field>
                                </div>
                            </div>
                            <div class="w-2/3 flex">
                                <div class="w-2/5 mx-1">
                                    <label
                                        class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                                        for="grid-city"
                                        >Quartier</label
                                    >
                                    <div class="h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="district_name"
                                            v-model="user.district_name"
                                        >
                                            <InputText
                                                v-bind="field"
                                                name="district_name"
                                                v-model="user.district_name"
                                                class="rounded border bg-white border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                id="grid-city"
                                                type="text"
                                                placeholder="Akpakpa"
                                            />
                                        </Field>
                                    </div>
                                </div>
                                <div class="w-2/5 mx-1">
                                    <label
                                        class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                                        for="grid-city"
                                        >Address</label
                                    >
                                    <div class="h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="address"
                                            v-model="user.address"
                                        >
                                            <InputText
                                                v-bind="field"
                                                name="address"
                                                v-model="user.address"
                                                class="rounded border border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                id="grid-city"
                                                type="text"
                                                placeholder="12, rue de la liberté"
                                        /></Field>
                                    </div>
                                </div>

                                <div class="w-1/5 ml-1">
                                    <label
                                        class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                                        for="grid-postal"
                                        >Code Postal</label
                                    >
                                    <div class="h-10">
                                        <Field
                                            v-slot="{ field }"
                                            name="postal_code"
                                            v-model="user.postal_code"
                                        >
                                            <InputText
                                                v-bind="field"
                                                name="postal_code"
                                                v-model="user.postal_code"
                                                class="rounded border bg-white border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                id="grid-postal"
                                                type="text"
                                                placeholder="67000"
                                            />
                                        </Field>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="w-1/3 mr-1">
                                <label
                                    class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                                    for="grid-email"
                                    >Description</label
                                >
                                <div class="h-32">
                                    <Field
                                        v-slot="{ field }"
                                        name="description"
                                        v-model="user.description"
                                    >
                                        <textarea
                                            v-bind="field"
                                            autocomplete="off"
                                            name="description"
                                            v-model="user.description"
                                            placeholder="Description"
                                            class="rounded border resize-none border-slate-300 bg-white h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                            type="text"
                                        ></textarea>
                                    </Field>
                                </div>
                            </div>
                        </div>

                        <div class="mt-5">
                            <Button
                                v-if="!isSubmittingProfile"
                                :disabled="!meta?.valid || !meta.dirty"
                                class="border-none rounded bg-primary3 py-2 px-4 text-white"
                                type="submit"
                            >
                                Enrégistrer
                            </Button>
                            <Button
                                v-else
                                type="button"
                                class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow text-white bg-primary hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
                                disabled=""
                            >
                                <svg
                                    class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        class="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        stroke-width="4"
                                    ></circle>
                                    <path
                                        class="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                                Sauvegarde en cours...
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="w-full flex flex-col px-3 mt-5">
                <div class="w-full py-3">
                    <h3 class="font-roboto font-bold text-lg text-slate-500">
                        Mot de passe
                    </h3>
                </div>
                <div class="flex">
                    <div class="w-1/3 mr-1">
                        <label
                            class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                            for="old"
                            >Actuel mot de passe</label
                        >
                        <div class="h-10">
                            <Field
                                v-slot="{ field }"
                                name="old"
                                v-model="password.old"
                            >
                                <InputText
                                    v-bind="field"
                                    name="old"
                                    class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    id="old"
                                    type="password"
                                    placeholder="Mot de passe"
                                    autocomplete="off"
                                />
                            </Field>
                        </div>
                    </div>
                    <div class="w-1/3 ml-1 mr-1">
                        <label
                            class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                            for="new"
                            >Nouveau mot de passe</label
                        >
                        <div class="h-10">
                            <Field
                                v-slot="{ field }"
                                name="new"
                                v-model="password.new"
                            >
                                <InputText
                                    name="new"
                                    v-bind="field"
                                    class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    id="new"
                                    type="password"
                                    placeholder="Nouveau"
                                />
                            </Field>
                        </div>
                    </div>
                    <div class="w-1/3 ml-1">
                        <label
                            class="block capitalize tracking-wide text-grey-darker text-xs font-medium mb-1"
                            for="confirm"
                            >Confirmer</label
                        >
                        <div class="h-10">
                            <Field
                                v-slot="{ field }"
                                name="confirm"
                                v-model="password.confirm"
                            >
                                <InputText
                                    v-bind="field"
                                    name="confirm"
                                    class="rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                    id="confirm"
                                    type="password"
                                    placeholder="Confirmation"
                                />
                            </Field>
                        </div>
                    </div>
                </div>
                <div class="mt-5 flex justify-start items-center">
                    <Button
                        v-if="!isSubmittingPassword"
                        size="sm"
                        variant="primary"
                        :disabled="passwordDisabled"
                        class="border-none rounded bg-primary3 py-2 px-3 text-white"
                        @click.prevent="savePassword"
                    >
                        Enrégistrer
                    </Button>
                    <Button
                        v-else
                        type="button"
                        class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow text-white bg-primary hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed"
                        disabled=""
                    >
                        <svg
                            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Sauvegarde en cours...
                    </Button>
                    <div class="mx-4" v-show="passwordError">
                        <h3 class="text-danger text-sm">{{ passwordError }}</h3>
                    </div>
                </div>
            </div>
        </div>
        <input
            class="hidden"
            ref="file"
            type="file"
            name="file"
            id="file"
            accept="image/*"
            @change="handleFile"
        />
        <Toast />
    </div>
</template>

<script>
import { Field, useForm } from "vee-validate";
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import InputText from "primevue/inputtext";
import Toast from "primevue/toast";
import Select from "../../components/Select/index.vue";
import * as yup from "yup";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Storage, { storageKeys } from "../../services/Storage";
import { useToast } from "primevue/usetoast";
export default defineComponent({
    setup() {
        const toast = useToast();
        const store = useStore();
        const file = ref();
        const isSubmittingProfile = ref(false);
        const isSubmittingPassword = ref(false);
        const auth = computed(() => store.getters["auth/user"]);
        const cities = computed(() =>
            store.getters["config/cities"].map((x) => ({
                id: x.id,
                name: x.name,
            }))
        );
        const countryCode = ref(Storage.get(storageKeys.country_code));
        const passwordError = ref();
        const user = ref({
            ...auth.value,
            district_name: auth.value?.profile?.district_name,
            postal_code: auth.value?.profile?.postal_code,
            address: auth.value?.profile?.address,
            description: auth.value?.profile?.description,
            phone_1: auth.value?.phone_1
                ? `${auth.value?.phone_1 ? "+" : ""}${auth.value?.phone_1}`
                : "",
            phone_2: auth.value?.phone_2
                ? `${auth.value?.phone_2 ? "+" : ""}${auth.value?.phone_2}`
                : "",
        });
        const password = ref({ old: "", new: "", confirm: "" });
        const passwordDisabled = computed(
            () =>
                !password.value.old ||
                password.value.new.length < 8 ||
                !password.value.new ||
                !password.value.confirm ||
                password.value.confirm != password.value.new
        );

        const schema = computed(() =>
            yup.object({
                last_name: yup.string().required(""),
                first_name: yup.string().required(""),
                first_name: yup.string().required(""),
                phone_1: yup.string().required(""),
                phone_2: yup.string().required(""),
                nickname: yup.string().optional().nullable().default(null),
                city: yup
                    .object({ id: yup.number() })
                    .required("Votre ville est obligatoire"),
                district_name: yup.string().required("La ville"),
                address: yup.string().required(),
                postal_code: yup.string().required(),
            })
        );

        const { meta, errors, setFieldValue, handleSubmit } = useForm({
            validationSchema: schema.value,
        });
        const getCities = () => {
            store.dispatch("config/getCities");
        };
        const handleCity = (data) => {
            setFieldValue("city", data.value);
        };

        const handleFile = (event) => {
            const [loaded] = event.target.files;
            const formData = new FormData();
            formData.append("image", loaded);
            store.dispatch("auth/updateAvatar", {
                data: formData,
                onSuccess: () => {
                    toast.add({
                        severity: "success",
                        summary: "Profile",
                        detail: "Votre profile a bien été mis à jour",
                        life: 3000,
                    });
                },
                onError: () => {
                    toast.add({
                        severity: "error",
                        summary: "Profile",
                        detail: "Ooops, une erreur est survenue lors de mis à jour",
                        life: 5000,
                    });
                },
            });
        };

        const submitForm = handleSubmit((values) => {
            isSubmittingProfile.value = true;
            const data = {
                district_name: values.district_name,
                address: values.address,
                description: values.description,
                marital_status: user.value.marital_status ?? "",
                birth_date: user.value.birth_date ?? "",
                gender: user.value.gender,
                email: user.value.email ?? values.email,
                postal_code: values.postal_code,
                nickname: values.nickname,
                last_name: values.last_name,
                first_name: values.first_name,
                city_id: values.city.id,
                phone_1: values.phone_1
                    ?.replaceAll("+", "")
                    .replaceAll(" ", ""),
                phone_2: values.phone_1
                    ?.replaceAll("+", "")
                    .replaceAll(" ", ""),
            };

            store.dispatch("auth/updateProfile", {
                data,
                onSuccess: () => {
                    isSubmittingProfile.value = false;

                    toast.add({
                        severity: "success",
                        summary: "Profile",
                        detail: "Votre profile a bien été mis à jour",
                        life: 3000,
                    });
                },
                onError: () => {
                    isSubmittingProfile.value = false;

                    toast.add({
                        severity: "error",
                        summary: "Profile",
                        detail: "Ooops, une erreur est survenue lors de mis à jour",
                        life: 5000,
                    });
                },
            });
        });

        const savePassword = () => {
            passwordError.value = "";
            if (
                password.value.new == password.value.old ||
                password.value.new.includes(password.value.old)
            ) {
                passwordError.value =
                    "L'ancien mot de passe et le nouveau doivent être différents";
                return;
            }
            isSubmittingPassword.value = true;
            store.dispatch("auth/updatePassword", {
                data: {
                    password_confirmation: password.value.confirm,
                    password: password.value.new,
                    old_password: password.value.old,
                },
                onSuccess: () => {
                    isSubmittingPassword.value = false;

                    toast.add({
                        severity: "success",
                        summary: "Profile - Mot de passe",
                        detail: "Votre mot de passe a bien été mis à jour",
                        life: 3000,
                    });
                    Storage.remove(storageKeys.authToken);
                    window.location.replace("/login");
                },
                onError: () => {
                    isSubmittingPassword.value = false;

                    toast.add({
                        severity: "error",
                        summary: "Profile - Mot de passe",
                        detail: "Ooops, une erreur est survenue lors de mis à jour",
                        life: 5000,
                    });
                },
            });
        };

        const loadFile = () => {
            file.value?.click();
        };

        watch(
            () => auth.value,
            (val) => {
                user.value = {
                    ...val,
                    city: { id: val.city.id, name: val?.city?.name },
                    district_name: auth.value?.profile?.district_name,
                    postal_code: val?.profile?.postal_code,
                    address: auth.value?.profile?.address,
                    description: auth.value?.profile?.description,
                    phone_1: auth.value?.phone_1
                        ? `${auth.value?.phone_1 ? "+" : ""}${
                              auth.value?.phone_1
                          }`
                        : "",
                    phone_2: auth.value?.phone_2
                        ? `${auth.value?.phone_2 ? "+" : ""}${
                              auth.value?.phone_2
                          }`
                        : "",
                };
            }
        );

        onMounted(() => {
            getCities();
        });

        return {
            auth,
            file,
            savePassword,
            countryCode,
            isSubmittingProfile,
            user,
            isSubmittingPassword,
            loadFile,
            cities,
            meta,
            passwordDisabled,
            errors,
            passwordError,
            password,
            handleCity,
            submitForm,
            handleFile,
        };
    },
    components: { Field, Toast, Select, InputText, Dropdown, Button },
});
</script>
