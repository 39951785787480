<template>
    <div class="w-full">
        <div class="mb-20">
            <div md="12">
                <div class="rounded-sm bg-white flex flex-col p-3 mb-5">
                    <div class="flex flex-row justify-between mb-1">
                        <div>
                            <h4 class="card-title text-xl font-medium">
                                Modification fiche patient
                                <strong class="text-warning">{{
                                    patient?.username
                                }}</strong>
                            </h4>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div md="12" class="table-responsive">
                                <form method="post" autocomplete="off">
                                    <div
                                        class="border-b-2 border-tertiary mb-3"
                                    >
                                        <h4
                                            class="mt-2 font-primary text-xl text-doto-100"
                                        >
                                            Information
                                        </h4>
                                    </div>
                                    <div class="flex w-full mb-2">
                                        <div class="w-1/4 pr-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-state"
                                                >Sexe</label
                                            >
                                            <div class="relative">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="gender"
                                                    v-model="patient.gender"
                                                >
                                                    <Select
                                                        v-bind="field"
                                                        class="w-full"
                                                        name="gender"
                                                        placeholder="Sexe"
                                                        v-model="patient.gender"
                                                        :options="genders"
                                                        @changed="
                                                            ([value]) =>
                                                                (patient.gender =
                                                                    value)
                                                        "
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/4 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-last-name"
                                                >Nom de famille</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="last_name"
                                                    v-model="patient.last_name"
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="last_name"
                                                        v-model="
                                                            patient.last_name
                                                        "
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-last-name"
                                                        type="text"
                                                        placeholder="Doe"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/4 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-first-name"
                                                >Prénom(s)</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="first_name"
                                                    v-model="patient.first_name"
                                                >
                                                    <input
                                                        name="first_name"
                                                        v-model="
                                                            patient.first_name
                                                        "
                                                        v-bind="field"
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-first-name"
                                                        type="text"
                                                        placeholder="Jane"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/4 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-phone-1"
                                                >Email</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="email"
                                                    v-model="patient.email"
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="email"
                                                        v-model="patient.email"
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="email"
                                                        type="text"
                                                        placeholder="test@example.com"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex w-full mb-1">
                                        <div class="w-1/4 pr-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-email"
                                                >Surnom (optionnel)</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="nickname"
                                                    v-model="patient.nickname"
                                                >
                                                    <input
                                                        v-bind="field"
                                                        autocomplete="off"
                                                        name="nickname"
                                                        v-model="
                                                            patient.nickname
                                                        "
                                                        placeholder="Surnom"
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        type="text"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/4 px-1 mb-3 md:mb-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-phone-1"
                                                >Numéro de téléphone
                                                principal</label
                                            >
                                            <div
                                                class="h-10 bg-white rounded border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                            >
                                                <Field
                                                    v-slot="{ field }"
                                                    name="phone_1"
                                                    v-model="patient.phone_1"
                                                >
                                                    <phone-number-input
                                                        type="text"
                                                        v-bind="field"
                                                        :defaultCountry="
                                                            countryCode
                                                        "
                                                        placeholder="Numero de télephone *"
                                                        class="h-full w-full bg-white px-2 text-base font-medium text-black outline-none"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/4 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-phone-2"
                                                >Numéro de téléphone</label
                                            >
                                            <div
                                                class="h-10 bg-white rounded border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                            >
                                                <Field
                                                    v-slot="{ field }"
                                                    name="phone_2"
                                                    v-model="patient.phone_2"
                                                >
                                                    <phone-number-input
                                                        type="text"
                                                        v-bind="field"
                                                        :defaultCountry="
                                                            countryCode
                                                        "
                                                        placeholder="Numero de télephone *"
                                                        class="h-full w-full bg-white px-2 text-base font-medium text-black outline-none"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="-mx-3 flex mb-3">
                                        <div
                                            class="md:w-2/3 pr-2 flex items-center"
                                        >
                                            <!-- <b-form-checkbox
                                                class="absolute opacity-0 left-0 top-0 cursor-pointer"
                                                type="checkbox"
                                                v-model="patient.generate_password"
                                                name="generate_password"
                                                >
                                                Générer un mot de passe
                                                </b-form-checkbox>-->
                                        </div>
                                    </div>
                                    <div
                                        class="border-b-2 border-tertiary mb-3"
                                    >
                                        <h4
                                            class="mt-3 font-primary text-xl text-doto-100"
                                        >
                                            Profile
                                        </h4>
                                    </div>
                                    <div class="flex mb-2">
                                        <div class="w-1/4 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-state"
                                                >Ville</label
                                            >
                                            <div class="relative">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="city"
                                                    v-model="patient.city"
                                                >
                                                    <Select
                                                        v-bind="field"
                                                        name="city"
                                                        v-model="patient.city"
                                                        :options="cities"
                                                        @changed="
                                                            ([city]) =>
                                                                (patient.city =
                                                                    city)
                                                        "
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/4 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-city"
                                                >Quartier</label
                                            >
                                            <Field
                                                v-slot="{ field }"
                                                name="district_name"
                                                v-model="patient.district_name"
                                            >
                                                <div class="h-10">
                                                    <input
                                                        v-bind="field"
                                                        name="district_name"
                                                        v-model="
                                                            patient.district_name
                                                        "
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-city"
                                                        type="text"
                                                        placeholder="Akpakpa"
                                                    />
                                                </div>
                                            </Field>
                                        </div>
                                        <div class="w-1/4 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-city"
                                                >Adresse</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="address"
                                                    v-model="patient.address"
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="address"
                                                        v-model="
                                                            patient.address
                                                        "
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-city"
                                                        type="text"
                                                        placeholder="12, rue de la liberté"
                                                    />
                                                </Field>
                                            </div>
                                        </div>

                                        <div class="w-1/4 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-postal"
                                                >Code Postal</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="postal_code"
                                                    v-model="
                                                        patient.postal_code
                                                    "
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="postal_code"
                                                        v-model="
                                                            patient.postal_code
                                                        "
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-postal"
                                                        type="text"
                                                        placeholder="67000"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex w-full">
                                        <div class="w-1/4 px-1 mb-3 md:mb-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-city"
                                                >Date de naissance</label
                                            >
                                            <div
                                                class="bg-white h-10 rounded border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                            >
                                                <Field
                                                    v-slot="{ field }"
                                                    name="birth_date"
                                                    v-model="patient.birth_date"
                                                >
                                                    <Datepicker
                                                        v-bind="field"
                                                        v-model="
                                                            patient.birth_date
                                                        "
                                                        name="birth_date"
                                                        id="birth_date"
                                                        placeholder="Date de naissance"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/4 px-1 mb-3 md:mb-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-profession"
                                                >Profession</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="profession"
                                                    v-model="patient.profession"
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="profession"
                                                        v-model="
                                                            patient.profession
                                                        "
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-profession"
                                                        type="text"
                                                        placeholder="Profession"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-2/6 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-postal"
                                                >Poids</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="weight"
                                                    v-model="patient.weight"
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="weight"
                                                        v-model="patient.weight"
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="grid-postal"
                                                        type="text"
                                                        placeholder="Poids(en g)"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-2/6 px-1 mb-3 md:mb-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-postal"
                                                >Taille</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="size"
                                                    v-model="patient.size"
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="size"
                                                        v-model="patient.size"
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="size"
                                                        type="text"
                                                        placeholder="Taille(en Cm)"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/6 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-state"
                                                >Groupe Sanguin</label
                                            >
                                            <div class="relative">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="blood_group"
                                                    v-model="
                                                        patient.blood_group
                                                    "
                                                >
                                                    <Select
                                                        v-bind="field"
                                                        class="w-full"
                                                        name="blood_group"
                                                        v-model="
                                                            patient.blood_group
                                                        "
                                                        placeholder="Groupe sanguin"
                                                        :options="bloodGroups"
                                                        @changed="
                                                            (value) =>
                                                                (patient.blood_group =
                                                                    value)
                                                        "
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <div class="w-1/4 px-1 mb-3 md:mb-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-city"
                                                >Nationalité</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="nationality"
                                                    v-model="
                                                        patient.nationality
                                                    "
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="nationality"
                                                        v-model="
                                                            patient.nationality
                                                        "
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="nationality"
                                                        type="text"
                                                        placeholder="Nationalité"
                                                    />
                                                </Field>
                                            </div>
                                        </div>

                                        <div class="w-1/4 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-state"
                                                >Situation maritale</label
                                            >
                                            <div class="relative">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="marital_status"
                                                    v-model="
                                                        patient.marital_status
                                                    "
                                                >
                                                    <Select
                                                        v-bind="field"
                                                        name="marital_status"
                                                        v-model="
                                                            patient.marital_status
                                                        "
                                                        :options="statuses"
                                                        @changed="
                                                            ([value]) =>
                                                                (patient.marital_status =
                                                                    value)
                                                        "
                                                    ></Select>
                                                </Field>
                                            </div>
                                        </div>

                                        <div class="w-1/4 px-1 mb-3 md:mb-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-profession"
                                                >Maison d'assurance</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="insurance_provider"
                                                    v-model="
                                                        patient.insurance_provider
                                                    "
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="insurance_provider"
                                                        v-model="
                                                            patient.insurance_provider
                                                        "
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="insurance_provider"
                                                        type="text"
                                                        placeholder="Axa"
                                                    />
                                                </Field>
                                            </div>
                                        </div>

                                        <div class="w-1/4 px-1 mb-3 md:mb-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-postal"
                                                >№ d'assuré</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="insurance_policy_number"
                                                    v-model="
                                                        patient.insurance_policy_number
                                                    "
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="insurance_policy_number"
                                                        v-model="
                                                            patient.insurance_policy_number
                                                        "
                                                        class="bg-white rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="insurance_policy_number"
                                                        type="text"
                                                        placeholder="AH-KIEK-393999930"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="border-b-2 border-tertiary">
                                        <h4
                                            class="mt-3 font-primary text-xl text-doto-100"
                                        >
                                            Adresse facturation
                                        </h4>
                                    </div>
                                    <div class="flex mt-2">
                                        <div class="w-1/5 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-first-name"
                                                >Nom de famille</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="invoice.last_name"
                                                    v-model="
                                                        patient.invoice
                                                            .last_name
                                                    "
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="invoice.last_name"
                                                        v-model="
                                                            patient.invoice
                                                                .last_name
                                                        "
                                                        class="bg-white h-full rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="invoice.last_name"
                                                        type="text"
                                                        placeholder="Saman"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/5 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-last-name"
                                                >Prénom(s)</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="invoice.first_name"
                                                    v-model="
                                                        patient.invoice
                                                            .first_name
                                                    "
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="invoice.first_name"
                                                        v-model="
                                                            patient.invoice
                                                                .first_name
                                                        "
                                                        class="bg-white h-full rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="invoice.first_name"
                                                        type="text"
                                                        placeholder="Mama"
                                                    />
                                                </Field>
                                            </div>
                                        </div>

                                        <div class="w-1/5 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-email"
                                                >Email</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="invoice.email"
                                                    v-model="
                                                        patient.invoice.email
                                                    "
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="invoice.email"
                                                        v-model="
                                                            patient.invoice
                                                                .email
                                                        "
                                                        class="bg-white h-full rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="invoice.email"
                                                        type="text"
                                                        placeholder="Email du payeur"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/5 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-email"
                                                >Téléphone</label
                                            >
                                            <div
                                                class="bg-white h-10 rounded border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                            >
                                                <Field
                                                    v-slot="{ field }"
                                                    name="invoice.phone_1"
                                                    v-model="
                                                        patient.invoice.phone_1
                                                    "
                                                >
                                                    <phone-number-input
                                                        type="text"
                                                        :disabled="
                                                            patient.doctor
                                                                .id !== 'other'
                                                        "
                                                        v-bind="field"
                                                        :defaultCountry="
                                                            countryCode
                                                        "
                                                        placeholder="Numero de télephone *"
                                                        class="h-full w-full bg-white px-2 text-base font-medium text-black outline-none"
                                                    />
                                                    <!-- <PhoneInput
                                                        v-bind="field"
                                                        v-model="
                                                            patient.invoice
                                                                .phone_1
                                                        "
                                                        name="invoice.phone_1"
                                                        @phoneChanged="
                                                            (number) =>
                                                                (patient.invoice.phone_1 =
                                                                    number)
                                                        "
                                                    /> -->
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/5 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-state"
                                                >Lien de parenté</label
                                            >
                                            <div class="relative">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="invoice.kinship"
                                                    v-model="
                                                        patient.invoice.kinship
                                                    "
                                                >
                                                    <Select
                                                        v-bind="field"
                                                        name="invoice.kinship"
                                                        v-model="
                                                            patient.invoice
                                                                .kinship
                                                        "
                                                        :options="kinships"
                                                        @changed="
                                                            ([value]) =>
                                                                (patient.invoice.kinship =
                                                                    value)
                                                        "
                                                    ></Select>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex mt-2"></div>
                                    <div
                                        class="border-b-2 border-tertiary mt-3 mb-2"
                                    >
                                        <h4 class="mt-3 text-xl text-doto-100">
                                            Médécin traitant
                                        </h4>
                                    </div>

                                    <div class="flex mb-1">
                                        <div class="w-1/5 px-1 md:mb-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-first-name"
                                                >Doctor</label
                                            >
                                            <Field
                                                v-slot="{ field }"
                                                name="doctor"
                                                v-model="patient.doctor"
                                            >
                                                <Select
                                                    v-bind="field"
                                                    name="doctor"
                                                    v-model="patient.doctor"
                                                    :options="[
                                                        ...doctors.map((x) => ({
                                                            ...x,
                                                            name: x.full_name,
                                                        })),
                                                        {
                                                            name: 'Autre',
                                                            id: 'other',
                                                        },
                                                    ]"
                                                    @changed="
                                                        (event) =>
                                                            (patient.doctor =
                                                                event)
                                                    "
                                                />
                                            </Field>
                                        </div>
                                        <div class="w-1/5 px-1 md:mb-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-first-name"
                                                >Nom de famille</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="doctor.last_name"
                                                    v-model="
                                                        patient.doctor.last_name
                                                    "
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="doctor.last_name"
                                                        :disabled="
                                                            patient.doctor
                                                                .id !== 'other'
                                                        "
                                                        class="bg-white h-full rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="doctor.last_name"
                                                        type="text"
                                                        placeholder="Saman"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/4 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-last-name"
                                                >Prénom(s)</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="doctor.first_name"
                                                    v-model="
                                                        patient.doctor
                                                            .first_name
                                                    "
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="doctor.first_name"
                                                        :disabled="
                                                            patient.doctor
                                                                .id !== 'other'
                                                        "
                                                        v-model="
                                                            patient.doctor
                                                                .first_name
                                                        "
                                                        class="bg-white h-full rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="doctor.first_name"
                                                        type="text"
                                                        placeholder="mamam"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/4 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-email"
                                                >Téléphone</label
                                            >
                                            <div
                                                class="bg-white h-10 rounded border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                            >
                                                <Field
                                                    v-slot="{ field }"
                                                    name="doctor.phone_1"
                                                    v-model="
                                                        patient.doctor.phone_1
                                                    "
                                                >
                                                    <phone-number-input
                                                        type="text"
                                                        :disabled="
                                                            patient.doctor
                                                                .id !== 'other'
                                                        "
                                                        v-bind="field"
                                                        :defaultCountry="
                                                            countryCode
                                                        "
                                                        placeholder="Numero de télephone *"
                                                        class="doctor-info-phone h-full w-full bg-white px-2 text-base font-medium text-black outline-none"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                        <div class="w-1/4 px-1">
                                            <label
                                                class="block tracking-wide text-grey-darker text-xs font-bold mb-1"
                                                for="grid-email"
                                                >Email</label
                                            >
                                            <div class="h-10">
                                                <Field
                                                    v-slot="{ field }"
                                                    name="doctor.email"
                                                    v-model="
                                                        patient.doctor.email
                                                    "
                                                >
                                                    <input
                                                        v-bind="field"
                                                        name="doctor.email"
                                                        v-model="
                                                            patient.doctor.email
                                                        "
                                                        :disabled="
                                                            patient.doctor.id !=
                                                            'other'
                                                        "
                                                        class="bg-white h-full rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                        id="doctor.email"
                                                        type="text"
                                                        placeholder="Email"
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="border-b-2 border-tertiary">
                                        <h4
                                            class="mt-3 text-xl mb-3 text-doto-100"
                                        >
                                            Santé
                                        </h4>
                                    </div>
                                    <div class="px-2">
                                        <div
                                            v-for="item in textRecords"
                                            :key="item.id"
                                            class="mt-3 p-1 rounded-sm flex justify-between items-center"
                                        >
                                            <div
                                                class="flex w-1/3 items-center"
                                            >
                                                <i
                                                    :class="`${item.icon_class} text-3xl mr-2`"
                                                ></i>
                                                <h5 class="font-primary">
                                                    {{ item.name }}
                                                </h5>
                                            </div>
                                            <div class="h-10 w-2/3">
                                                <Field
                                                    v-slot="{ field }"
                                                    :name="`records.${item.slug}`"
                                                    v-model="
                                                        patient.records[
                                                            item.slug
                                                        ]
                                                    "
                                                >
                                                    <input
                                                        v-bind="field"
                                                        :name="`records.${item.slug}`"
                                                        v-model="
                                                            patient.records[
                                                                item.slug
                                                            ]
                                                        "
                                                        class="w-2/3 bg-white h-full rounded border border-slate-300 w-full px-2 focus:border-slate-400 transition transition-all"
                                                        placeholder
                                                    />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between px-2">
                                        <div class style="width: 48%">
                                            <div
                                                v-for="item in checkboxRecords"
                                                :key="item.id"
                                                class="mt-3 p-1 w-100 flex justify-between rounded-sm"
                                                style="height: 4em"
                                            >
                                                <div class="flex items-center">
                                                    <i
                                                        :class="`${item.icon_class} text-3xl mr-2`"
                                                    ></i>
                                                    <h5 class="font-primary">
                                                        {{ item.name }}
                                                    </h5>
                                                </div>

                                                <div
                                                    class="flex items-center mr-4"
                                                >
                                                    <Field
                                                        v-slot="{ field }"
                                                        type="checkbox"
                                                        :value="true"
                                                        :unchecked-value="false"
                                                        :name="`records.${item.slug}`"
                                                        v-model="
                                                            patient.records[
                                                                item.slug
                                                            ]
                                                        "
                                                    >
                                                        <input
                                                            v-bind="field"
                                                            name="records"
                                                            id="inline-checkbox"
                                                            type="checkbox"
                                                            :value="true"
                                                            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                        />
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class style="width: 48%">
                                            <div
                                                v-for="item in numberRecords"
                                                :key="item.id"
                                                class="flex justify-between rounded-sm items-center mt-3 p-1"
                                            >
                                                <div class="flex items-center">
                                                    <i
                                                        :class="`${item.icon_class} text-3xl mr-2`"
                                                    ></i>
                                                    <h5 class="font-primary">
                                                        {{ item.name }}
                                                    </h5>
                                                </div>
                                                <div
                                                    class="w-25 flex items-center"
                                                >
                                                    <Field
                                                        v-slot="{ field }"
                                                        :name="`records.${item.slug}`"
                                                        v-model="
                                                            patient.records[
                                                                item.slug
                                                            ]
                                                        "
                                                    >
                                                        <input
                                                            v-bind="field"
                                                            :name="`records.${item.slug}`"
                                                            v-model="
                                                                patient.records[
                                                                    item.slug
                                                                ]
                                                            "
                                                            type="number"
                                                            class="bg-slate-100 h-10 rounded border border-slate-300 h-full w-full px-2 focus:border-slate-400 transition transition-all"
                                                            placeholder
                                                        />
                                                    </Field>
                                                    <span
                                                        class="p-2"
                                                        style="width: 4em"
                                                    >
                                                        {{ item.data_unity }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="border-b-2 border-tertiary mt-3"
                                    >
                                        <h4
                                            class="mt-5 font-primary text-xl text-doto-100"
                                        >
                                            Notification
                                        </h4>
                                    </div>
                                    <div class="flex mt-4 mb-10">
                                        <div class="flex items-center mr-4">
                                            <Field
                                                v-slot="{ field }"
                                                type="checkbox"
                                                value="sms"
                                                name="notification"
                                                v-model="patient.notification"
                                            >
                                                <input
                                                    v-bind="field"
                                                    id="inline-2-radio"
                                                    type="checkbox"
                                                    name="notification"
                                                    value="sms"
                                                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                            </Field>
                                            <label
                                                for="inline-2-radio"
                                                class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                >SMS</label
                                            >
                                        </div>
                                        <div class="flex items-center mr-4">
                                            <Field
                                                v-slot="{ field }"
                                                type="checkbox"
                                                value="email"
                                                name="notification"
                                                v-model="patient.notification"
                                            >
                                                <input
                                                    v-bind="field"
                                                    id="inline-2-radio"
                                                    name="notification"
                                                    value="email"
                                                    type="checkbox"
                                                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                            </Field>
                                            <label
                                                for="inline-2-radio"
                                                class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                >E-mail</label
                                            >
                                        </div>
                                    </div>

                                    <div class="flex justify-end my-5">
                                        <button
                                            class="mr-1 border-none rounded bg-danger mx-3 px-3 text-white"
                                            @click.prevent="cancel()"
                                        >
                                            Fermer
                                        </button>
                                        <button
                                            :disabled="!meta.valid"
                                            :class="`mr-1 border-none rounded bg-${
                                                meta.valid
                                                    ? 'success'
                                                    : 'gray-400'
                                            } mx-3 px-3 py-2 text-white`"
                                            @click.prevent="
                                                upadatePatient(false)
                                            "
                                        >
                                            Enrégistrer
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";
import { mapGetters, useStore } from "vuex";
import PhoneInput from "@/components/PhoneInput";
import Select from "../../components/Select/index.vue";
import Datepicker from "../../components/Datepicker";
import { useForm, Field } from "vee-validate";
import * as yup from "yup";
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted, watch, computed } from "vue";

import { kinskipsData, bloodGroupData, genderData } from "@/Utils/data";
import { TYPE } from "vue-toastification";
export default {
    components: { PhoneInput, Select, Datepicker, Field },
    beforeRouteEnter(to, from, next) {
        store.dispatch("config/getCities");
        store.dispatch("config/getAllRecordTypes");
        store.dispatch("config/getResources", {}).then((res) => next());
    },

    setup(props) {
        const patient = ref({
            city: null,
            last_name: "",
            first_name: "",
            email: "",
            phone_1: "",
            phone_2: "",
            blood_group: "",
            district_name: "",
            address: "",
            postal_code: "",
            profession: "",
            birth_date: new Date(1990, 12, 20, 17, 50, 9),
            weight: "",
            size: "",
            marital_status: null,
            notification: [],
            invoice: {
                last_name: "",
                first_name: "",
                email: "",
                kinship: null,
                phone_1: "",
            },
            doctor: {
                phone_1: "",
            },
            records: {},
        });
        const userData = ref(null);
        const hospital = ref(null);
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const portfolio = computed(() => store.getters["auth/portfolio"]);
        const kinships = computed(() => kinskipsData);
        const statuses = computed(() => [
            { name: "Célibataire", id: "single" },
            { name: "Marié(e)", id: "married" },
            {
                name: "Pacsé ou en concubinage",
                id: "cohabitation",
            },
            { name: "Séparé", id: "separate" },
            { name: "Divorcé", id: "divorced" },
            { name: "Veuf", id: "widower" },
        ]);
        const bloodGroups = computed(() => bloodGroupData);
        const genders = computed(() => genderData);
        const schema = yup.object({
            blood_group: yup
                .object({ id: yup.string() })
                .required("Le groupe sanguin est obligatoire"),
            gender: yup
                .object({ id: yup.string() })
                .required("Le sexe est requis"),
            last_name: yup.string().required("Le nom est requis"),
            first_name: yup.string().required("Le prénom est requis"),
            phone_1: yup.string().required("Un numero de téléphone est requis"),
            district_name: yup.string().required("Le quartier est requis"),
            weight: yup.string().required("Le poids est requis"),
            size: yup.string().required("La taille est requis"),
            insurance_provider: yup
                .string()
                .optional()
                .nullable()
                .default(null),
            insurance_policy_number: yup.string().when("insurance_provider", {
                is: (val) => val !== null,
                then: (schema) =>
                    schema.required(
                        "Entrer le numero de la police d'assurance"
                    ),
                otherwise: (schema) => schema.optional().nullable(),
            }),
            marital_status: yup
                .object({ id: yup.string() })
                .required("La situation familiale est requis"),
            city: yup
                .object({ id: yup.string() })
                .required("La ville est obligatoire"),
            invoice: yup.object({
                last_name: yup
                    .string()
                    .required("Le nom est requis (Facturation)"),
                first_name: yup
                    .string()
                    .required("Le prénom est requis (Facturation)"),
                email: yup
                    .string()
                    .required("Le prénom  est requis (Facturation)"),
                phone_1: yup
                    .string()
                    .required(
                        "Un numero de téléphone est requis (Facturation)"
                    ),
                kinship: yup
                    .object({ id: yup.string() })
                    .required("Le lien de parenté est requis (Facturation)"),
            }),
            doctor: yup.object({
                last_name: yup
                    .string()
                    .required("Le nom est requis (Médécin traitant)"),
                first_name: yup
                    .string()
                    .required("Le prénom est requis (Médécin traitant)"),
                email: yup
                    .string()
                    .required("Le prénom  est requis (Médécin traitant)"),
                phone_1: yup
                    .string()
                    .optional(
                        "Un numero de téléphone est requis (Médécin traitant)"
                    )
                    .nullable(),
            }),
        });
        const { errors, values, meta, resetForm, handleReset } = useForm({
            validationSchema: schema,
            validateOnMount: false,
            initialValues: patient.value,
        });

        const getPatient = () => {
            if (!route.params?.patientId) return;
            store.dispatch("medical/loadPatient", {
                identifier: route.params.patientId,
                onSuccess: (data) => {
                    patient.value = {
                        id: data.id,
                        last_name: data?.last_name,
                        first_name: data?.first_name,
                        email: data?.email,
                        username: data?.username,
                        nickname: data?.nickname,
                        gender: { id: data.gender },
                        phone_1: `+${data.phone_1}`,
                        phone_2: `+${data.phone_2}`,
                        city: data.city,
                        district_name: data.profile.district_name,
                        address: data.address,
                        postal_code: data.profile.postal_code,
                        birth_date: data.profile.birth_date,
                        profession: data.profile.profession,
                        weight: data.profile.weight,
                        nationality: data.profile.nationality,
                        blood_group: {
                            id: data.profile.blood_group,
                        },
                        size: data.profile.size,
                        marital_status: {
                            id: data.profile.marital_status,
                        },
                        doctor: {
                            id: data?.profile?.doctor?.id,
                            name: data?.profile?.doctor?.full_name,
                            last_name: data?.profile?.doctor?.last_name,
                            first_name: data?.profile?.doctor?.first_name,
                            email: data?.profile?.doctor?.email,
                            phone_1: `+${data?.profile?.doctor?.phone_1}`,
                        },
                        insurance_policy_number:
                            data.profile?.insurance_policy_number,
                        insurance_provider: data.profile?.insurance_provider,
                        invoice: {
                            id: data?.relations[0]?.id,
                            last_name: data?.relations[0]?.last_name,
                            first_name: data?.relations[0]?.first_name,
                            email: data?.relations[0]?.email,
                            phone_1: `+${data?.relations[0]?.phone_1}`,
                            kinship: { id: data?.relations[0]?.pivot?.kinship },
                        },
                        notification:
                            data?.profile?.notification_system.split(","),
                        records: data.records.reduce((rec, line) => {
                            if (line?.type?.slug) {
                                if (
                                    ["text", "number"].includes(
                                        line.type.data_type
                                    )
                                )
                                    rec[line.type.slug] = line.value;
                                else if (line.type.data_type === "checkbox")
                                    rec[line.type.slug] =
                                        line.value === "1" ? true : false;
                            }

                            return rec;
                        }, {}),
                    };

                    userData.value = patient.value;
                },
                onError: () => {},
            });
        };
        const getHospital = () => {
            if (!route.params?.slug) return;
            store.dispatch("medical/getHospital", {
                slug: route.params.slug,
                onSuccess: (data) => {
                    hospital.value = { ...data };
                },
                onError: () => {},
            });
        };

        const getDoctors = () => {
            store.dispatch("medical/getDoctors", {
                filters: { portfolioId: portfolio.value?.id },
            });
        };

        const upadatePatient = (redirect) => {
            let data = {
                ...values,
                blood_group: values?.blood_group?.id,
                city_id: values?.city?.id,
                marital_status: values?.marital_status?.id,
            };
            data.gender = values.gender?.id;
            data.invoice.id = userData.value.invoice?.id;
            data.invoice.kinship = values.invoice.kinship?.id;
            if (hospital.value?.id) data.medical_center_id = hospital.value.id;
            data.phone_1 = values.phone_1
                ?.replaceAll("+", "")
                ?.replaceAll(" ", "");
            data.phone_2 = values.phone_2
                ?.replaceAll(" ", "")
                ?.replaceAll("+", "");
            data.doctor.phone_1 = values.doctor?.phone_1
                ?.replaceAll(" ", "")
                ?.replaceAll("+", "");
            data.invoice.phone_1 = values.invoice?.phone_1
                ?.replaceAll("+", "")
                ?.replaceAll(" ", "");

            if (!data.doctor.id == "other") {
                data.doctor_id = data.doctor.id;
                delete data.doctor;
            }

            // if (data.invoice.id) {
            //   data.invoice_id = data.invoice.id;
            //   delete data.invoice;
            // }

            console.log("dtaa", data, values);
            store.dispatch("medical/updatePatient", {
                id: patient.value.id,
                data: { ...data, portfolio_id: portfolio.value.id },
                onSuccess: (data) => {
                    // if (img.value && data?.id) {
                    //   saveImage();
                    // }
                    store.dispatch("medical/displayToast", {
                        message: "Fiche Patient mise à avec succès.",
                        context: hospital.value?.name || portfolio.value?.name,
                        type: TYPE.SUCCESS,
                    });
                    handleReset();
                    if (hospital.value?.id) {
                        router.push({
                            name: "config.hospitalDetails",
                            params: { slug: route.params?.slug },
                        });
                    } else cancel();
                },
                onError: (response) => {
                    store.dispatch("medical/displayToast", {
                        message: "Une erreur est survenue lors de la création.",
                        context: hospital.value?.name || portfolio.value?.name,
                        type: TYPE.ERROR,
                    });
                },
            });
        };

        const cancel = () => {
            init();
            router.push({
                name: "app.patients",
            });
        };
        const init = () => {
            patient.value = {
                city: null,
                last_name: "",
                first_name: "",
                email: "",
                phone_1: "",
                phone_2: "",
                blood_group: null,
                district_name: "",
                address: "",
                postal_code: "",
                profession: "",
                birth_date: "",
                weight: "",
                size: "",
                marital_status: null,
                notification: [],
                invoice: {
                    last_name: "",
                    first_name: "",
                    email: "",
                    kinship: null,
                    phone_1: "",
                },
                doctor: {
                    phone_1: "",
                },
                records: [],
            };
            handleReset();
            resetForm();
        };

        watch(
            () => portfolio.value,
            (val) => {
                router.push({ name: "app.patients" });
            }
        );
        onMounted(() => {
            getPatient();
            getHospital();
            getDoctors();
            init();
        });

        return {
            errors,
            statuses,
            kinships,
            bloodGroups,
            genders,
            values,
            patient,
            meta,
            upadatePatient,
            cancel,
        };
    },
    data() {
        return {};
    },
    methods: {
        increase(index) {
            this.resources[index].quantity += 1;
        },

        addError(key, errors) {
            const field = this.$validator.fields.find({
                name: key,
                scope: this.$options.scope,
            });

            if (!field) return;

            this.$validator.errors.add({
                id: field.id,
                field: key,
                msg: errors[0],
                scope: this.$options.scope,
            });

            field.setFlags({
                invalid: true,
                valid: false,
                validated: true,
            });
        },
        init() {
            this.patient = {
                city_id: "",
                last_name: "",
                first_name: "",
                email: "",
                phone_1: "",
                phone_2: "",
                blood_group: "",
                district_name: "",
                address: "",
                postal_code: "",
                profession: "",
                birth_date: "",
                weight: "",
                size: "",
                marital_status: "",
                notification: [],
                invoice: {
                    last_name: "",
                    first_name: "",
                    email: "",
                    kinship: "",
                    phone_1: "",
                },
                doctor: {
                    phone_1: "",
                },
                records: {},
            };
        },
        decrease(index) {
            if (this.resources[index].quantity > 0)
                this.resources[index].quantity -= 1;
        },
        add() {
            for (let index = 0; index < this.resources.length; index++) {
                const element = this.resources[index];
                if (
                    element.name.length < 10 ||
                    parseInt(element.quantity) <= 0 ||
                    element.description.length < 10
                ) {
                    return;
                }
            }
            this.resources.push({
                name: "",
                quantity: 1,
                description: "",
                type_id: "",
            });
        },
    },
    computed: {
        ...mapGetters({
            recordTypes: "config/allRecordTypes",
            recordSections: "config/allRecordSections",
            cities: "config/cities",
            doctors: "medical/doctors",
        }),
        types() {
            return this.recordTypes.sort((x, y) =>
                x.data_type == "text" ? -1 : 0
            );
        },

        textRecords() {
            return this.recordTypes.filter((x) => x.data_type === "text");
        },
        numberRecords() {
            return this.recordTypes.filter((x) => x.data_type === "number");
        },
        checkboxRecords() {
            return this.recordTypes.filter((x) => x.data_type === "checkbox");
        },
    },
};
</script>
<style lang="scss">
input:focus {
    box-shadow: none !important;
}
.checkbox-custom,
.radio-custom {
    opacity: 0 !important;
    position: absolute !important;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
    display: inline-block !important;
    vertical-align: middle !important;
    margin: 5px !important;
    cursor: pointer !important;
}

.checkbox-custom-label,
.radio-custom-label {
    position: relative !important;
}

.checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
    content: "" !important;
    background: #fff !important;
    border: 2px solid #ddd !important;
    display: inline-block !important;
    vertical-align: middle !important;
    width: 20px !important;
    height: 20px !important;
    padding: 2px !important;
    margin-right: 10px !important;
    text-align: center !important;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
    background: rebeccapurple !important;
    box-shadow: inset 0px 0px 0px 4px #fff !important;
}

.radio-custom + .radio-custom-label:before {
    border-radius: 50% !important;
}

.radio-custom:checked + .radio-custom-label:before {
    background: #ccc !important;
    box-shadow: inset 0px 0px 0px 4px #fff !important;
}

.checkbox-custom:focus + .checkbox-custom-label,
.radio-custom:focus + .radio-custom-label {
    outline: 1px solid #ddd !important; /* focus style */
}
input[type="checkbox"]:checked {
    @apply outline-none bg-primary  #{!important};
}
</style>
